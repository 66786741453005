import React, { useMemo, useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Scatter } from 'recharts';
import { useQuery, gql } from '@apollo/client';
import { Widget } from 'components/Widget';
import { ArrowTrendingLinesRegular, ChevronDownRegular } from "@fluentui/react-icons";
import { makeStyles, tokens, Dropdown, Option, Button } from "@fluentui/react-components";
import accountStore from 'stores/accountStore';
import { GET_ACCOUNT_BALANCES } from './queries';
import { startOfDay, subDays } from 'date-fns';
import useTimeframeStore from 'stores/TimeframeStore';

const useStyles = makeStyles({
    wrapper: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 12px 40px rgba(0, 0, 0, 0.12)'
        }
    },
    filterContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '24px',
        padding: '0 20px',
        position: 'relative',
        zIndex: 1
    },
    dropdownTrigger: {
        backgroundColor: 'white',
        border: '1px solid rgb(225, 223, 221)',
        borderRadius: '8px',
        padding: '8px 16px',
        minWidth: '160px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
            borderColor: tokens.colorNeutralStroke1Hover
        },
        '& svg': {
            fontSize: '12px',
            marginLeft: '8px'
        }
    },
dropdownList: {
    backgroundColor: 'white',
    border: '1px solid rgb(225, 223, 221)',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    marginTop: '4px',
    padding: '4px',
    width: '160px',
    maxWidth: '160px',       // Prevents expansion
    boxSizing: 'border-box', // Prevents content from expanding the box
    overflowY: 'auto',        // Allows only vertical scrolling if needed
    overflowX: 'hidden',      // Completely disables horizontal scrolling
    whiteSpace: 'nowrap'      // Prevents text wrapping
},
    option: {
        padding: '8px 16px',
        cursor: 'pointer',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover
        },
        '&[data-selected=true]': {
            backgroundColor: tokens.colorBrandBackground,
            color: 'white'
        }
    },
    chartContainer: {
        width: '100%',
        height: '500px',
        padding: '20px'
    },
    loadingState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        color: tokens.colorNeutralForeground2,
        fontSize: '1.1rem'
    },
    errorState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '400px',
        color: tokens.colorPaletteRedForeground1,
        padding: '24px',
        textAlign: 'center'
    }
});


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                background: 'rgba(255, 255, 255, 0.95)',
                padding: '12px',
                border: '1px solid #eee',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
            }}>
                <p style={{ margin: '0 0 8px', fontWeight: 'bold' }}>
                    {new Date(label).toLocaleDateString()}
                </p>
                {payload.map((entry, index) => (
                    <p key={index} style={{ margin: '4px 0', color: entry.color }}>
                        {`${entry.name}: $${entry.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

const CHART_COLORS = [
    '#3B82F6', // Blue
    '#10B981', // Green
    '#F59E0B', // Yellow
    '#EF4444', // Red
    '#8B5CF6', // Purple
    '#EC4899', // Pink
    '#14B8A6', // Teal
    '#F97316'  // Orange
];

const GET_TRANSACTION_TOTALS = gql`
  query getTransactionTotals($applicationId: String!, $contactId: String!, $accountIds: [String!]) {
    transactionTotals(applicationId: $applicationId, contactId: $contactId, accountIds: $accountIds) {
      totalTransactions
      totalDeposits
      totalSpending
      totalPayments
      averageDailyBalance
      daysWithNegativeBalance
      
      monthlyTotals {               
        totalTransactions
        totalDeposits
        totalSpending
        totalPayments
        averageDailyBalance
        daysWithNegativeBalance
        
        dailyBalances {           
          date
          balance
        }
      }

      dailyBalances {
        accountId 
        date
        balance
      }
    }
  }
`;

const TransactionChart = ({ application }) => {
    const classes = useStyles();
    const { startDate, endDate, setTimeframe } = useTimeframeStore();
    const { selectedAccounts } = accountStore();

    const { data, loading, error, refetch } = useQuery(GET_TRANSACTION_TOTALS, {
        variables: {
            applicationId: application.id,
            contactId: application.primaryContact.contactId,
            accountIds: selectedAccounts,
            startDate: startDate,
            endDate: endDate
        },
        skip: !selectedAccounts || selectedAccounts.length === 0,
    });

    // Refetch data when timeRange changes
    useEffect(() => {
        if (selectedAccounts && selectedAccounts.length > 0) {
            refetch({
                startDate,
                endDate
            });
        }
    }, [startDate, endDate, selectedAccounts, refetch]);




    const processedData = useMemo(() => {
        if (!data?.transactionTotals?.dailyBalances || !data.transactionTotals.dailyBalances.length) {
            return [];
        }

        const now = new Date();
        const startDateCopy = new Date(startDate);
        startDateCopy.setHours(0, 0, 0, 0);
        const dateAccountMap = new Map();

        data.transactionTotals.dailyBalances.forEach(({ date, balance, accountId }) => {
            const transactionDate = new Date(date);
            if (transactionDate >= startDate && transactionDate <= endDate) {
                if (!dateAccountMap.has(date)) {
                    dateAccountMap.set(date, {
                        date,
                    });
                }
                const dateEntry = dateAccountMap.get(date);
                dateEntry[accountId] = balance;
            }
        });

        const sortedData = Array.from(dateAccountMap.values())
            .sort((a, b) => new Date(a.date) - new Date(b.date));

        const allDates = [];
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dateStr = currentDate.toISOString().split('T')[0];
            allDates.push(dateStr);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const filledData = allDates.map(date => {
            const existingData = sortedData.find(d => d.date.includes(date)) || { date };
            return existingData;
        });

        return filledData;
    }, [data, startDate, endDate]);

    const accountIds = useMemo(() => {
        if (!data?.transactionTotals?.dailyBalances) return [];
        return [...new Set(data.transactionTotals.dailyBalances.map(b => b.accountId))];
    }, [data]);

    // Handler for dropdown option selection
    const handleTimeframeChange = (event, data) => {
        const days = parseInt(data.optionValue);
        const newEndDate = new Date();
        const newStartDate = new Date();
        newStartDate.setDate(newStartDate.getDate() - days);
        setTimeframe(newStartDate, newEndDate);
        console.log(days)
    };

    // Get the current timeframe in days for the dropdown
    const getCurrentTimeframe = () => {
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        console.log(diffDays);
        console.log(diffTime);
        return diffDays.toString();
    };
    const renderWidget = (content) => (
        <div className={classes.wrapper}>
            <Widget title="Account Daily Balances" image={<ArrowTrendingLinesRegular />}>
                <div className={classes.filterContainer}>
                    <Dropdown
                        className={classes.dropdownTrigger}
                        value={`Last ${getCurrentTimeframe()} Days`}
                        onOptionSelect={handleTimeframeChange}
                    >
                        <Option value="30">Last 30 Days</Option>
                        <Option value="60">Last 60 Days</Option>
                        <Option value="90">Last 90 Days</Option>
                        <Option value="180">Last 6 Months</Option>
                        <Option value="365">Last 1 Year</Option>
                    </Dropdown>
                </div>
                {content}
            </Widget>
        </div>
    );

    if (loading) return renderWidget(
        <div className={classes.loadingState}>Loading account data...</div>
    );

    if (error) return renderWidget(
        <div className={classes.errorState}>Error loading data: {error.message}</div>
    );

    if (!processedData.length) return renderWidget(
        <div className={classes.loadingState}>No data available for selected accounts</div>
    );

    return renderWidget(
        <div className={classes.chartContainer}>
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={processedData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 60 }}
                >
                    <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="rgba(0,0,0,0.1)"
                        vertical={false}
                    />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(date) => new Date(date).toLocaleDateString()}
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        tick={{ fontSize: 12 }}
                    />
                    <YAxis
                        tickFormatter={(value) => `$${value.toLocaleString()}`}
                        tick={{ fontSize: 12 }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        verticalAlign="top"
                        height={36}
                        wrapperStyle={{
                            paddingBottom: '20px',
                            fontSize: '12px'
                        }}
                    />
                    {accountIds.map((accountId, index) => (
                        <React.Fragment key={accountId}>
                            <Line
                                type="monotone"
                                dataKey={accountId}
                                name={`Account ${accountId.slice(-4)}`}
                                stroke={CHART_COLORS[index % CHART_COLORS.length]}
                                strokeWidth={2}
                                dot={false}
                                activeDot={{ r: 8 }}
                                connectNulls={true}
                            />
                            <Scatter
                                dataKey={accountId}
                                fill={CHART_COLORS[index % CHART_COLORS.length]}
                                line={false}
                                shape="circle"
                                r={4}
                            />
                        </React.Fragment>
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

// Helper function to calculate start date based on time range
 const calculateStartDate = (timeRange) => {
    const date = new Date();
    date.setDate(date.getDate() - timeRange);
    return date;
};

export default TransactionChart;