import React, { useState, useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';
import { makeStyles, shorthands, tokens, useClasses, mergeClasses } from "@fluentui/react-components";
import useMessageStore from 'stores/messageStore';
import { Toolbar, ToolbarButton, ToolbarDivider, ToolbarGroup } from "@fluentui/react-components";
import { SearchBox, Tag, TagGroup, TagGroupProps } from '@fluentui/react-components';
import { subDays } from 'date-fns';
import { Spinner } from "@fluentui/react-components";
import useAccountStore from 'stores/accountStore';
import useTimeframeStore from 'stores/TimeframeStore';

import {
    CalendarArrowDownFilled,
    WindowArrowUpRegular,
    AttachFilled,
    ArrowDownloadFilled,
    ArrowSyncCircleRegular,
    SearchFilled,
    CalendarSearchRegular,
    ArrowSyncCircle24Regular,
    ChevronRightRegular,
    SearchRegular
} from "@fluentui/react-icons";

import {
    PresenceBadgeStatus,
    DataGridBody,
    DataGridRow,
    DataGrid,
    DataGridProps,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridCell,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
} from "@fluentui/react-components";
import { renderHeaderCell } from 'react-data-grid';

import DateRangeSelectDialog from 'dialogs/DateRangeSelect';



const useStyles = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '24px',
        margin: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
    },
    expandedContent: {
        padding: '16px 24px',
        backgroundColor: tokens.colorNeutralBackground2,
        borderTop: `1px solid ${tokens.colorNeutralStroke1}`,
    },
    detailsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '16px',
    },
    detailRow: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    detailLabel: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
        fontWeight: '500',
    },
    detailValue: {
        fontSize: '14px',
        color: tokens.colorNeutralForeground1,
    },
    headerBar: {
        height: "48px",
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        padding: '0 12px',
        marginBottom: '16px',
        gap: '12px'
    },
    headerButton: {
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            transform: 'translateY(-1px)'
        }
    },
    searchBox: {
        minWidth: '300px',
        '& input': {
            borderRadius: '8px',
            transition: 'all 0.2s ease',
            '&:focus': {
                borderColor: tokens.colorBrandBackground,
                boxShadow: `0 0 0 2px ${tokens.colorBrandBackground}20`
            }
        }
    },
    dataGrid: {
        backgroundColor: 'transparent',
        minHeight: "calc(100vh - 205px)",
        maxHeight: "calc(100vh - 205px)",
        overflowY: "auto",
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        '& [role="row"]': {
            transition: 'background-color 0.2s ease',
            '&:hover': {
                backgroundColor: 'rgba(0, 120, 212, 0.05)'
            }
        }
    },
    overlaySpinner: {
        position: 'fixed',
        top: '0',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '40px',
        zIndex: 9999,
    },
    spinnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '24px',
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    spinnerIcon: {
        width: '32px',
        height: '32px',
        animation: 'spin 1s linear infinite',
    },
    spinnerText: {
        fontSize: '16px',
        color: tokens.colorNeutralForeground1,
        fontWeight: '500',
    },
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    gridHeader: {
        background: tokens.colorBrandBackground2,
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        position: 'sticky',
        top: 0,

        zIndex: 1,
        '& th': {
            color: tokens.colorNeutralForeground1,
            fontWeight: '500', 
            padding: '14px 24px',
            fontSize: '13px',
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
        }
    },
    tagGroup: {
        padding: '8px 0',
        gap: '8px',
        flexWrap: 'wrap'
    },
    tag: {
        borderRadius: '16px',
        backgroundColor: 'rgba(0, 120, 212, 0.1)',
        color: tokens.colorBrandForeground1,
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.15)'
        }
    },
    dateRangeButton: {
        backgroundColor: 'rgba(0, 120, 212, 0.08)',
        borderRadius: '8px',
        padding: '6px 12px',
        '&:hover': {
            backgroundColor: 'rgba(0, 120, 212, 0.15)'
        }
    },
    spinner: {
        position: "absolute",
        zIndex: "200",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: '20px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)'
    },
    cellAmount: {
        fontFamily: 'monospace',
        fontWeight: '500',
        '&[data-negative="true"]': {
            color: tokens.colorPaletteRedForeground1
        },
        '&[data-positive="true"]': {
            color: tokens.colorPaletteGreenForeground1
        }
    },
    toolbarGroup: {
        gap: '8px',
        alignItems: 'center'
    },
     accountName: {
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
    },
    accountNumber: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
    },
    secondaryText: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
        marginTop: '2px',
    }
});


const getColumns = (classes) => [
 
   
    createTableColumn({
        columnId: "date",
        compare: (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate),
        renderHeaderCell: () => "Date",
        renderCell: (item) => (
            <TableCellLayout>
                {new Date(item.transactionDate).toLocaleDateString()}
            </TableCellLayout>
        )
    }),
    createTableColumn({
        columnId: "institution",
        renderHeaderCell: () => "Institution",
        renderCell: (item) => (
            <TableCellLayout>
                <div>
                    <div>{item.institutionId}</div>

                </div>
            </TableCellLayout>
        )
    }),
 
    createTableColumn({
        columnId: "account",
        compare: (a, b) => a.accountName.localeCompare(b.accountName),
        renderHeaderCell: () => "Account",
        renderCell: (item) => (
            <TableCellLayout>
                <div>
                    <div className={classes.accountName}>{item.accountName}</div>
                    <div className={classes.accountNumber}>****{item.accountNumberDisplay}</div>
                </div>
            </TableCellLayout>
        )
    }),
    createTableColumn({
        columnId: "description",
        compare: (a, b) => a.description.localeCompare(b.description),
        renderHeaderCell: () => "Description",
        renderCell: (item) => (
            <TableCellLayout>
                <div>
                    <div>{item.payeeName}</div>
                    {item.payeeName !== item.description && (
                        <div className={classes.secondaryText}>{item.description}</div>
                    )}
                </div>
            </TableCellLayout>
        )
    }),
    createTableColumn({
        columnId: "source",
        compare: (a, b) => a.source.localeCompare(b.source),
        renderHeaderCell: () => "Source",
        renderCell: (item) => (
            <TableCellLayout>
                <div>

                    <div >{item.source}</div>

                </div>
            </TableCellLayout>
        )
    }),

    
    createTableColumn({
        columnId: "category",
        compare: (a, b) => (a.category || '').localeCompare(b.category || ''),
        renderHeaderCell: () => "Category",
        renderCell: (item) => (
            <TableCellLayout>
                {item.category || 'Uncategorized'}
            </TableCellLayout>
        )
    }),
    createTableColumn({
        columnId: "amount",
        compare: (a, b) => a.amount - b.amount,
        renderHeaderCell: () => "Amount",
        renderCell: (item) => {
            const { amount, source } = item;

            let isNegative;
            if (source?.toLowerCase() === 'plaid') {
                // For Plaid: positive amounts are expenses (negative), negative are deposits (positive)
                isNegative = amount > 0;
            } else {
                // For Finicity: negative amounts are expenses (negative), positive are deposits (positive)
                isNegative = amount < 0;
            }

            const formattedAmount = Math.abs(amount).toLocaleString("en-us", {
                style: "currency",
                currency: "USD"
            });

            return (
                <TableCellLayout>
                    <span
                        className={classes.cellAmount}
                        data-negative={isNegative}
                        data-positive={!isNegative}
                    >
                        {isNegative ? '-' : ''}{formattedAmount}
                    </span>
                </TableCellLayout>
            );
        }
    })
];

//const TransactionDetails = ({ transaction, classes }) => (
//    <div className={classes.expandedContent}>
//        <div className={classes.detailsGrid}>
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Transaction ID</div>
//                <div className={classes.detailValue}>{transaction.transactionId}</div>
//            </div>
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Source</div>
//                <div className={classes.detailValue}>{transaction.source}</div>
//            </div>
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Type</div>
//                <div className={classes.detailValue}>{transaction.type || 'N/A'}</div>
//            </div>
//            {transaction.checkNumber && (
//                <div className={classes.detailRow}>
//                    <div className={classes.detailLabel}>Check Number</div>
//                    <div className={classes.detailValue}>{transaction.checkNumber}</div>
//                </div>
//            )}
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Full Description</div>
//                <div className={classes.detailValue}>{transaction.description}</div>
//            </div>
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Payee Name</div>
//                <div className={classes.detailValue}>{transaction.payeeName}</div>
//            </div>
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Category</div>
//                <div className={classes.detailValue}>{transaction.category || 'Uncategorized'}</div>
//            </div>
//            <div className={classes.detailRow}>
//                <div className={classes.detailLabel}>Account Details</div>
//                <div className={classes.detailValue}>
//                    {transaction.accountName} (****{transaction.accountNumberDisplay})
//                </div>
//            </div>
//        </div>
//    </div>
//);

const columnSizingOptions = {

};

const useClassesTransactions = makeStyles({
    root: {

    },
    container: {
        backgroundColor: tokens.colorNeutralBackground1,
        padding: "20px",
        minHeight: "100vh",
    },
    headerButton: {
        marginRight: "5px",
        backgroundColor: tokens.colorNeutralBackground4,
    },
    headerBar:
    {
        height: "48px",
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        padding: '0 12px',
        marginBottom: '16px',
    },
    dataGrid:
    {
        backgroundColor: 'transparent',
        minHeight: "calc(100vh - 205px)",
        maxHeight: "calc(100vh - 205px)",
        overflowY: "auto",
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        overflowY: "scroll"
    },
    spinner: {
        position: "absolute",
        zIndex: "200",
        left: "0",
        right: "0",
        top: "250px",
    },
});

export const GET_ACCOUNTTRANSACTIONS = gql`
  query getAccountTransactions(
    $applicationId: String!, 
    $contactId: String!, 
    $startDate: DateTime!, 
    $endDate: DateTime!,
    $accountIds: [String!]
  ) {
    transactions(
      applicationId: $applicationId, 
      contactId: $contactId, 
      startDate: $startDate, 
      endDate: $endDate,
      accountIds: $accountIds
    ) {
      accountId
      accountNumberDisplay
      accountName
      institutionId
      institutionName
      institutionLogo
      institutionIcon
      transactionId
      category
      payeeName
      description
      type
      status
      transactionDate
      amount
      checkNumber
      source
    }
  }
`;


const TransactionsPage = ({ application, onRequestRefresh }) => {
    const classes = useStyles();
    const { selectedAccounts } = useAccountStore();
    const messageStore = useMessageStore();
    const [isBusy, setIsBusy] = useState(true);
    const [visibleTags, setVisibleTags] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
    const { startDate, endDate, setTimeframe } = useTimeframeStore();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [expandedRows, setExpandedRows] = useState(new Set());

    const { loading, error, data, refetch } = useQuery(GET_ACCOUNTTRANSACTIONS, {
        variables: { applicationId: application.id, contactId: application.primaryContact.contactId, startDate, endDate, accountIds: selectedAccounts },
        onCompleted: data => {
           
            setTransactions(data.transactions);
            setIsBusy(false);
        },
    });

    const handleRowClick = (transactionId) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(transactionId)) {
                newSet.delete(transactionId);
            } else {
                newSet.add(transactionId);
            }
            return newSet;
        });
    };

    const handleRefresh = async () => {
        setIsBusy(true);
        setIsLoadingData(true);
        try {
            const result = await refetch();
            setTransactions(result.data.transactions || []);
        } finally {
            setIsBusy(false);
            setIsLoadingData(false);
        }
    };

    const applySearch = async (tags) => {
        if (!data?.transactions) return;
        setIsLoadingData(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 100));
            let filteredTransactions = data.transactions;
            tags.forEach((tag) => {
                filteredTransactions = filteredTransactions.filter(item =>
                    item.description.toLowerCase().includes(tag.text.toLowerCase()));
            });
            setTransactions(filteredTransactions);
        } finally {
            setIsLoadingData(false);
        }
    };

    const handleSearch = useCallback(async () => {
        if (!searchText) return;
        setIsLoadingData(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 100));
            const newTags = [...visibleTags, { value: visibleTags.length + 1, text: searchText }];
            setVisibleTags(newTags);
            await applySearch(newTags);
            setSearchText('');
        } finally {
            setIsLoadingData(false);
        }
    }, [searchText, visibleTags]);


    const removeTag = (event, { value }) => {
        const updatedTags = visibleTags.filter(tag => tag.value !== value);
        setVisibleTags(updatedTags);

        if (updatedTags.length === 0) {
            // Reset to original data if no tags
            setTransactions(data.transactions || []);
        } else {
            // Reapply remaining filters
            const filteredTransactions = data.transactions.filter(transaction =>
                updatedTags.every(tag =>
                    transaction.description.toLowerCase().includes(tag.text.toLowerCase()) ||
                    transaction.payeeName.toLowerCase().includes(tag.text.toLowerCase())
                )
            );
            setTransactions(filteredTransactions);
        }
    };

    const handleDateRangeSelect = async (start, end) => {
        try {
            setIsLoadingData(true);
            setTimeframe(start, end);
            setShowDateRangeDialog(false);

            const result = await refetch({
                variables: {
                    applicationId: application.id,
                    contactId: application.primaryContact.contactId,
                    startDate: start,
                    endDate: end,
                    accountIds: selectedAccounts
                }
            });

            setTransactions(result.data.transactions || []);
        } catch (error) {
            messageStore.addMessage('Error', 'Failed to fetch transactions for selected date range');
        } finally {
            setIsLoadingData(false);
        }
    };




    return (
        <div className={classes.root}>
            <DateRangeSelectDialog
                startDate={startDate}
                endDate={endDate}
                showDialog={showDateRangeDialog}
                onClose={() => setShowDateRangeDialog(false)}
                onSelect={handleDateRangeSelect}
            />

            <div className={classes.headerSection}>
                <Toolbar className={classes.headerBar}>
                    <ToolbarGroup className={classes.toolbarGroup}>
                        <SearchBox
                            placeholder="Search transactions..."
                            value={searchText}
                            onChange={(event) => setSearchText(event.target.value)}
                            className={classes.searchBox}
                             onKeyDown={(event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
            }
        }}
                        />
                        <button
                            onClick={handleSearch}
                            className={classes.headerButton}
                        >
                            <SearchRegular />
                            Search
                        </button>
                    </ToolbarGroup>

                    <ToolbarGroup className={classes.toolbarGroup}>
                        <button
                            onClick={() => setShowDateRangeDialog(true)}
                            className={mergeClasses(classes.headerButton, classes.dateRangeButton)}
                        >
                            <CalendarSearchRegular />
                            {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                        </button>
                        <button
                            onClick={onRequestRefresh}
                            className={classes.headerButton}
                        >
                            <ArrowSyncCircleRegular />
                            Refresh
                        </button>
                    </ToolbarGroup>
                </Toolbar>

                {visibleTags.length > 0 && (
                    <TagGroup
                        onDismiss={removeTag}
                        aria-label="Active filters"
                        className={classes.tagGroup}
                    >
                        {visibleTags.map((tag, index) => (
                            <Tag
                                dismissible
                                key={index}
                                value={tag.value}
                                className={classes.tag}
                            >
                                {tag.text}
                            </Tag>
                        ))}
                    </TagGroup>
                )}
            </div>

            <DataGrid
                className={classes.dataGrid}
                items={transactions}
                columnSizingOptions={columnSizingOptions}
                columns={getColumns(classes)}
                sortable
                getRowId={(item) => item.id}
                focusMode="composite"
            >
                {isBusy && (
                    <div className={classes.spinner}>
                        <Spinner label="Loading transactions..." />
                    </div>
                )}
                <DataGridHeader className={classes.gridHeader}>
                    <DataGridRow>
                        {({ renderHeaderCell }) => (
                            <DataGridHeaderCell>
                                {renderHeaderCell()}
                            </DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody>
                    {({ item, rowId }) => (
                        <DataGridRow key={rowId}>
                            {({ renderCell }) => (
                                <DataGridCell>
                                    {renderCell(item)}
                                </DataGridCell>
                            )}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>
            {isLoadingData && (
                <div className={classes.overlaySpinner}>
                    <div className={classes.spinnerContainer}>
                        <ArrowSyncCircle24Regular className={classes.spinnerIcon} />
                        <span className={classes.spinnerText}>Loading Transactions...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransactionsPage;