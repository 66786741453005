import { create } from 'zustand';
import { subDays } from 'date-fns';

const useTimeframeStore = create((set) => ({
	startDate: subDays(new Date(), 90), // Default to 90 days
	endDate: new Date(),
	setTimeframe: (startDate, endDate) => set({ startDate, endDate }),
	
}));

export default useTimeframeStore;