import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { TenantApiClient } from 'providers/TenantApiClient';
import {
    Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell,
    DataGridRow, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, mergeClasses, Tab,
    TableCellLayout, TabList, tokens, Card, Caption1, Avatar, Tooltip, Spinner, Dropdown, Option
} from "@fluentui/react-components";
import {
    MoreVerticalRegular, EditRegular, DeleteRegular, PeopleCheckmarkRegular,
    SendRegular, SendFilled, ArrowSyncRegular
} from "@fluentui/react-icons";
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend } from 'chart.js';
import useMessageStore from 'stores/messageStore';
import useTenantStore from "stores/tenantStore";
import ShortcutCard from 'components/Shortcut';
import CreateApplicationDialog from '../dialogs/CreateApplication';
import AddMemberDialog from '../dialogs/AddAgent';
import AssignApplicationDialog from '../dialogs/AssignApplication';
import DeleteApplicationDialog from '../dialogs/DeleteApplication';
import RequestFollowUpDialog from '../dialogs/RequestFollowUp';
import finicityLogo from '../assets/images/logos/finicity-logo.png';
import plaidLogo from '../assets/images/logos/plaid-logo.png';
import backgroundImage from '../assets/images/backgrounds/crm-dark.png';
import backgroundImage2 from '../assets/images/backgrounds/crm-light.png';
import useUserStore from 'stores/userStore';
import ActivityLog from './Admin/Tabs/ActivityLog';


Chart.register(ArcElement, ChartTooltip, Legend);

const STATUS_OPTIONS = [
    { value: 'Created', label: 'Created' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Started', label: 'Started' },
    { value: 'WaitingForApplicant', label: 'Waiting for Applicant' },
    { value: 'Processing', label: 'Processing' },
    { value: 'Ready', label: 'Ready' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Canceled', label: 'Canceled' }
];


const getStatusColor = (status) => {
    const statusColors = {
        Created: { bg: '#E6FFFA', text: '#234E52' },
        Pending: { bg: '#EBF4FF', text: '#2C5282' },
        Started: { bg: '#E9F5FE', text: '#2B6BA1' },
        WaitingForApplicant: { bg: '#FEF3C7', text: '#92400E' },
        Processing: { bg: '#E9ECEF', text: '#495057' },
        Ready: { bg: '#F0FFF4', text: '#22543D' },
        Approved: { bg: '#C6F6D5', text: '#276749' },
        Rejected: { bg: '#FED7D7', text: '#9B2C2C' },
        Declined: { bg: '#FED7D7', text: '#9B2C2C' },
        Canceled: { bg: '#E2E8F0', text: '#4A5568' }
    };
    return statusColors[status] || { bg: '#EDF2F7', text: '#4A5568' };
};
   const useStyles = makeStyles({
       root: {
           padding: '32px',
           backgroundImage: `url(${backgroundImage2})`,
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           backgroundRepeat: 'no-repeat',
           backgroundAttachment: 'fixed',
           minHeight: '100vh'
       },
       statsCard: {
           padding: '24px',
           backgroundColor: 'rgba(255, 255, 255, 0.9)',
           borderRadius: '16px',
           boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
           display: 'flex',
           flexDirection: 'column',
           height: '400px', // Fixed height
           position: 'relative',
           transition: 'transform 0.2s ease, box-shadow 0.2s ease',
           '&:hover': {
               transform: 'translateY(-4px)',
               boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)'
           },
           '&::before': {
               content: '""',
               position: 'absolute',
               top: 0,
               left: 0,
               right: 0,
               height: '4px',
               background: 'linear-gradient(90deg, #3B82F6 0%, #10B981 100%)'
           }
       },
       statsContainer: {
           display: 'grid',
           gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
           gap: '24px',
           marginBottom: '32px'
       },
       cardTitle: {
           fontSize: '1.75rem',
           fontWeight: '600',
           color: '#2563EB',
           background: 'linear-gradient(90deg, #2563EB 0%, #3B82F6 100%)',
           WebkitBackgroundClip: 'text',
           WebkitTextFillColor: 'transparent',
           textAlign: 'left',
           marginBottom: '20px',
           lineHeight: '1.2',
           display: 'block',
           width: '100%',
           flexShrink: 0, // Prevent title from shrinking
           '@media (max-width: 640px)': {
               fontSize: '1.5rem'
           }
       },
       cardValue: {
           fontSize: '3.25rem',
           fontWeight: '700',
           color: '#1e293b',
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           flexShrink: 0, // Prevent value from shrinking
           background: 'linear-gradient(135deg, #1e293b, #334155)',
           WebkitBackgroundClip: 'text',
           WebkitTextFillColor: 'transparent',
           letterSpacing: '-0.02em',
           lineHeight: '1.2',
           marginBottom: '20px',
           '@media (max-width: 1024px)': {
               fontSize: '2.5rem',
           },
           '@media (max-width: 768px)': {
               fontSize: '2rem',
           },
           '@media (max-width: 480px)': {
               fontSize: '1.5rem',
           }
       },
       contentWrapper: {
           flex: 1,
           overflowY: 'auto',
           minHeight: 0, // Important for proper flex behavior with overflow
           // Custom scrollbar styling
           '&::-webkit-scrollbar': {
               width: '8px',
           },
           '&::-webkit-scrollbar-track': {
               background: 'rgba(0, 0, 0, 0.05)',
               borderRadius: '4px',
           },
           '&::-webkit-scrollbar-thumb': {
               background: 'rgba(0, 0, 0, 0.2)',
               borderRadius: '4px',
               '&:hover': {
                   background: 'rgba(0, 0, 0, 0.3)',
               }
           }
       },
        mainGrid: {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
            overflow: 'hidden',
            position: 'relative'
        },
        tabList: {
            padding: '16px 24px 0',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor: 'rgba(247, 250, 252, 0.8)',
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '24px',
                right: '24px',
                height: '1px',
                background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent)'
            }
        },
        gridHeader: {
            background: 'linear-gradient(180deg, rgba(247, 250, 252, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            '& th': {
                color: '#1e293b',
                fontWeight: '600',
                padding: '14px 24px',
                fontSize: '13px',
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
            }
        },
        row: {
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
                backgroundColor: 'rgba(59, 130, 246, 0.05)',
                transform: 'translateY(-1px)',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
            }
        },
        statusBadge: {
            padding: '6px 12px',
            borderRadius: '9999px',
            fontSize: '0.875rem',
            fontWeight: '500',
            display: 'inline-flex',
            alignItems: 'center',
            gap: '6px',
            transition: 'all 0.2s ease',
            '&::before': {
                content: '""',
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: 'currentColor',
                opacity: 0.7
            }
        },
       shortcutCardWrapper: {
           display: 'flex',
           justifyContent: 'center',
           position: 'absolute',
           bottom: '24px',
           right: '24px',
           transition: 'all 0.2s ease-in-out',
           zIndex: 2,
           '@media (max-width: 768px)': {
               position: 'relative',
               bottom: '16px',
               right: '16px',
               margin: '0 auto',
               display: 'flex',
               justifyContent: 'center',
           },
           '&:hover': {
               transform: 'scale(1.05)',
               '& > *': {
                   backgroundColor: 'rgba(59, 130, 246, 0.08)',
                   borderColor: '#3B82F6'
               }
           }
       },

        chartWrapper: {
            padding: '20px',
            position: 'relative',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: '10%',
                right: '10%',
                height: '1px',
                background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent)'
            }
        },
        pieChart: {
            maxWidth: '300px',
            margin: '0 auto',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                inset: '-10px',
                background: 'radial-gradient(circle at center, rgba(59, 130, 246, 0.05) 0%, transparent 70%)',
                borderRadius: '50%'
            }
        },
        avatar: {
            width: '32px',
            height: '32px',
            borderRadius: '8px',
            transition: 'transform 0.2s ease',
            '&:hover': {
                transform: 'scale(1.1)'
            }
        },
        icon: {
            color: '#3B82F6',
            fontSize: '24px',
            transition: 'transform 0.2s ease',
            '&:hover': {
                transform: 'scale(1.1)'
            }
        },
        flex: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        },
        menuButton: {
            backgroundColor: 'transparent',
            transition: 'all 0.2s ease',
            '&:hover': {
                backgroundColor: 'rgba(59, 130, 246, 0.08)',
                transform: 'translateY(-1px)'
            }
       },
       cardContent: {
           display: 'flex',
           flexDirection: 'column',
           alignItems: 'center',
           justifyContent: 'center',
           width: '100%',
           position: 'relative',
           zIndex: 1
       },
    });

const HomePage = () => {
    const classes = useStyles();
    const messageStore = useMessageStore();
    const tenantStore = useTenantStore();
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState('last-updated');
    const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'
    const [userData, setUserData] = useState(null);
    const [isStatusUpdating, setIsStatusUpdating] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [applications, setApplications] = useState([]);
    const [tab, setTab] = useState('');
    const [dialogState, setDialogState] = useState({
        create: false,
        addMember: false,
        assign: false,
        delete: false,
        resend: false,
        selectedApp: null,
        selectedContact: null
    });


 

    // Add these helper components to enhance the UI
    const StatCard = ({ title, value, children }) => {
        const classes = useStyles();
        return (
            <div className={classes.statsCard}>
                <h3 className={classes.cardTitle}>{title}</h3>
                {value && <div className={classes.cardValue}>{value}</div>}
                <div className={classes.contentWrapper}>
                    {children}
                </div>
            </div>
        );
    };



    const fetchApplications = useCallback(async () => {
        if (!userData) return;

        try {
            const isAgent = tenantStore.tenantUsers?.find(
                user => user.email?.toLowerCase() === userData.email?.toLowerCase()
            )?.roles?.includes('Agent');
        
            const userDetails = {
                email: userData.email,
                id: "MBR-1049ed4031006a8",
                mobilePhone: "0123456789",
                name: "aaron",
                roles: ['owner'],
                status: "Pending",
                title: null
            };

            useUserStore.getState().setUser(userDetails);

            const endpoint = isAgent ? '/api/applications' : '/api/applications/all';
            const data = await TenantApiClient.get(endpoint);
            setApplications(data);
        } catch (error) {
            messageStore.addAxiosError("Failed to return Applicants", error);
        } finally {
            setIsLoading(false);
        }
    }, [userData, tenantStore.tenantUsers]);

    // Single effect to handle initialization
    useEffect(() => {
        const initializeData = async () => {
            try {
                // First verify user is authenticated
                const userResponse = await TenantApiClient.get('/api/user');
                const userEmail = userResponse?.email?.toLowerCase();
               
               
                if (!userEmail) {
                    console.error("User not authenticated");
                    return;
                }

                // Then get applications based on user role
                const isAgent = tenantStore?.tenantUsers?.find(
                    user => user.email?.toLowerCase() === userEmail
                )?.roles?.includes('Agent');

      
                    
                const endpoint = isAgent ? '/api/applications' : '/api/applications/all';
                const applicationsData = await TenantApiClient.get(endpoint);

                setApplications(applicationsData);
                setIsLoading(false);
            } catch (error) {
                messageStore.addAxiosError("Failed to initialize", error);
                setIsLoading(false);
            }
        };

        // Run initialization
        initializeData();
    }, [messageStore, tenantStore]); // Include dependencies to allow recheck


    const handleStatusUpdate = useCallback(async (application, newStatus) => {


        setIsStatusUpdating(true);

        const updateRequest = {
            name: application.name,
            description: application.description,
            loanConsiderationLow: application.loanConsiderationLow,
            loanConsiderationHigh: application.loanConsiderationHigh,
            status: newStatus
        };
        try {
            await TenantApiClient.put(`/api/applications/${application.id}`, updateRequest);
            
            messageStore.addMessage(`Status updated to ${newStatus} successfully!`);
            fetchApplications();
        } catch (error) {
            messageStore.addAxiosError("Failed to update status", error);
        } finally {
            setIsStatusUpdating(false);
        }
    }, [fetchApplications, messageStore]);

    

    useEffect(() => {
        fetchApplications();

    }, [fetchApplications]);

    const filteredApplications = useMemo(() =>
        tab ? applications.filter(a => a.status === tab) : applications,
        [applications, tab]
    );

    const sortedApplications = useMemo(() => {
        if (!sortColumn) return filteredApplications;
        return [...filteredApplications].sort((a, b) => {
            switch (sortColumn) {
                case 'last-updated':
                    const dateA = new Date(a.updateWhen);
                    const dateB = new Date(b.updateWhen);
                    return sortDirection === 'asc'
                        ? dateA - dateB
                        : dateB - dateA;
                // Add other cases for different columns if needed
                default:
                    return 0;
            }
        });
    }, [filteredApplications, sortColumn, sortDirection]);

    const handleAction = useCallback((action, application, e) => {
        e?.stopPropagation();

        switch (action) {
            case 'edit':
                navigate(`/applicant/${application.id}`);
                break;
            case 'delete':
            case 'assign':
            case 'resend':
                setDialogState(prev => ({
                    ...prev,
                    [action]: true,
                    selectedApp: application,
                    selectedContact: action === 'resend' ? application.contacts?.[0] : null
                }));
                break;
            default:
                break;
        }
    }, [navigate]);

    const handleDialogClose = useCallback((dialogType, shouldRefresh = false) => {
        setDialogState(prev => ({
            ...prev,
            [dialogType]: false,
            selectedApp: null,
            selectedContact: null
        }));

        if (shouldRefresh) {
            fetchApplications();
        }
    }, [fetchApplications]);

    const columns = useMemo(() => [
        createTableColumn({
            columnId: 'name',
            renderHeaderCell: () => 'Name',
            renderCell: (application) => (
                <TableCellLayout>
                    {application.primaryContact.firstName} {application.primaryContact.lastName}
                </TableCellLayout>
            ),
            compare: (a, b) => {
                const nameA = `${a.primaryContact.firstName} ${a.primaryContact.lastName}`.toLowerCase();
                const nameB = `${b.primaryContact.firstName} ${b.primaryContact.lastName}`.toLowerCase();
                return nameA.localeCompare(nameB);
            }
        }),
       
         createTableColumn({
             columnId: 'LoanAmount',
             renderHeaderCell: () => 'Loan Amount',
             renderCell: (application) => (
                 <TableCellLayout>
                      ${application.loanConsiderationLow}-${application.loanConsiderationHigh}
                     </TableCellLayout>
             ),
         }),
        createTableColumn({
            columnId: 'LoanType',
            renderHeaderCell: () => 'Loan Type',
            renderCell: (application) => (
                <TableCellLayout>
                    {application.applicationType}
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: 'created-date',
            renderHeaderCell: () => 'Created On',
            renderCell: (application) => (
                <TableCellLayout>
                    {new Date(application.createdDate).toLocaleDateString()}
                </TableCellLayout>
            ),
            sortable: true,
            compare: (a, b) => {
                const dateA = new Date(a.updateWhen);
                const dateB = new Date(b.updateWhen);
                return dateA - dateB;
            },
        }),
        createTableColumn({
            columnId: 'status',
            renderHeaderCell: () => 'Status',
            renderCell: (application) => (
                <TableCellLayout>
                    <div
                        className={classes.statusBadge}
                        style={{
                            backgroundColor: getStatusColor(application.status).bg,
                            color: getStatusColor(application.status).text
                        }}
                    >
                        {application.status}
                    </div>
                </TableCellLayout>
            ),
            compare: (a, b) => a.status.localeCompare(b.status)
        }),
        createTableColumn({
            columnId: 'lending-agent',
            renderHeaderCell: () => 'Lending Agent',
            renderCell: (application) => <TableCellLayout>{application.createdWho}</TableCellLayout>,
            compare: (a, b) => a.createdWho.localeCompare(b.createdWho)
        }),
        createTableColumn({
            columnId: 'last-updated',
            renderHeaderCell: () => 'Last Updated',
            renderCell: (application) => (
                <TableCellLayout>
                    {new Date(application.updateWhen).toLocaleString()}
                </TableCellLayout>
            ),
            sortable: true,
            compare: (a, b) => {
                const dateA = new Date(a.updateWhen);
                const dateB = new Date(b.updateWhen);
                return dateA - dateB;
            },
        }),
        createTableColumn({
            columnId: 'icons',
            renderHeaderCell: () => null,
            renderCell: (application) => (
                <TableCellLayout>
                    <div className={mergeClasses(classes.flex, classes.footer)}>
                        {application.primaryContact.plaidLink && (
                            <Tooltip content="Plaid Linked" relationship="label">
                                <Avatar name="Plaid" className={classes.flex} image={{ src: plaidLogo }} />
                            </Tooltip>
                        )}
                        {application.primaryContact.finicityLink && (
                            <Tooltip content="Finicity Linked" relationship="label">
                                <Avatar name="Finicity" className={classes.flex} image={{ src: finicityLogo }} />
                            </Tooltip>
                        )}
                    </div>
                </TableCellLayout>
            ),
        }),

        createTableColumn({
            columnId: 'menu',
            renderHeaderCell: () => null,
            renderCell: (item) => (
                <TableCellLayout className={classes.colRight}>
                    <div className={mergeClasses(classes.flex, classes.footer)}>
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Button
                                icon={<MoreVerticalRegular />}
                                onClick={e => e.stopPropagation()}
                                disabled={isStatusUpdating}
                            />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem icon={<EditRegular />} onClick={e => handleAction('edit', item, e)}>
                                    Edit
                                </MenuItem>
                                <MenuItem icon={<DeleteRegular />} onClick={e => handleAction('delete', item, e)}>
                                    Delete
                                </MenuItem>
                                <MenuItem icon={<PeopleCheckmarkRegular />} onClick={e => handleAction('assign', item, e)}>
                                    Assign
                                </MenuItem>
                                <MenuItem icon={<SendRegular />} onClick={e => handleAction('resend', item, e)}>
                                    Resend Application
                                </MenuItem>
                                <MenuList>
                                    <MenuItem
                                        icon={<ArrowSyncRegular />}
                                        disabled={isStatusUpdating}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <Dropdown
                                                className={classes.statusDropdown}
                                                value={item.status}
                                                onOptionSelect={(_, data) => {
                                                    if (data.optionValue !== item.status) {
                                                        handleStatusUpdate(item, data.optionValue);
                                                    }
                                                }}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                {STATUS_OPTIONS.map(status => (
                                                    <Option
                                                        key={status.value}
                                                        value={status.value}
                                                        className={classes.statusOption}
                                                    >
                                                        <div
                                                            className={classes.statusBadge}
                                                            style={{
                                                                backgroundColor: getStatusColor(status.value).bg,
                                                                color: getStatusColor(status.value).text
                                                            }}
                                                        >
                                                            {status.label}
                                                        </div>
                                                    </Option>
                                                ))}
                                            </Dropdown>
                                        </div>
                                    </MenuItem>
                                </MenuList>
                            </MenuList>
                        </MenuPopover>
                        </Menu>
                    </div>
                </TableCellLayout>
            ),
        }),
       
    ], [classes, handleAction, handleStatusUpdate, isStatusUpdating]);

    const countByStatus = useMemo(() => {
        return applications.reduce((acc, app) => {
            acc[app.status] = (acc[app.status] || 0) + 1;
            return acc;
        }, {});
    }, [applications]);

    const chartData = {
        labels: Object.keys(countByStatus),
        datasets: [{
            data: Object.values(countByStatus),
            backgroundColor: [
                '#4CAF50', '#FFEB3B', '#FFC107', '#FF9800', '#F44336',
                '#2196F3', '#3F51B5', '#9C27B0', '#607D8B', '#795548'
            ]
        }]
    };

    return (
        <>
            <CreateApplicationDialog
                showDialog={dialogState.create}
                onCancelDialog={() => handleDialogClose('create')}
                onComplete={applicationId => {
                    handleDialogClose('create');
                    navigate('/Applicant/' + applicationId);
                }}
            />
            <AddMemberDialog
                tenantId={tenantStore.id}
                showDialog={dialogState.addMember}
                onCancel={() => handleDialogClose('addMember')}
                onComplete={() => handleDialogClose('addMember')}
            />
            <AssignApplicationDialog
                showDialog={dialogState.assign}
                onCancel={() => handleDialogClose('assign')}
                application={dialogState.selectedApp}
                onComplete={() => handleDialogClose('assign', true)}
            />
            <DeleteApplicationDialog
                showDialog={dialogState.delete}
                onCancel={() => handleDialogClose('delete')}
                application={dialogState.selectedApp}
                onComplete={success => handleDialogClose('delete', success)}
            />
            <RequestFollowUpDialog
                showDialog={dialogState.resend}
                onCancel={() => handleDialogClose('resend')}
                application={dialogState.selectedApp}
                contacts={dialogState.selectedContact}
                onRequestSent={() => handleDialogClose('resend')}
            />
            <div className={classes.root}>
                <div className={classes.statsContainer}>
                    <StatCard title="Applications" value={applications.length}>
                        <div className={classes.shortcutCardWrapper}>
                            <ShortcutCard
                                title="Create New Invitation"
                                onClick={() => setDialogState(prev => ({ ...prev, create: true }))}
                                icon={<SendFilled className={classes.icon} />}
                            />
                        </div>
                    </StatCard>

                    <StatCard title="Users" value={tenantStore.tenantUsers.length}>
                        <div className={classes.shortcutCardWrapper}>
                            <ShortcutCard
                                title="Create New Agent"
                                onClick={() => setDialogState(prev => ({ ...prev, addMember: true }))}
                                icon={<PeopleCheckmarkRegular className={classes.icon} />}
                            />
                        </div>
                    </StatCard>

                    <StatCard title="Status Distribution">
                        <div className={classes.chartWrapper}>
                            <div className={classes.pieChart}>
                                <Pie
                                    data={chartData}
                                    options={{
                                        plugins: {
                                            legend: {
                                                position: 'bottom',
                                                labels: {
                                                    usePointStyle: true,
                                                    padding: 20
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </StatCard>
                    <StatCard title="Notifications">
                        <ActivityLog />
                    </StatCard>
                </div>
         
                <Card className={classes.mainGrid}>
                    <TabList
                        className={classes.tabList}
                        selectedValue={tab}
                        onTabSelect={(_, data) => setTab(data.value)}
                    >
                        <Tab value="">All</Tab>
                        <Tab value="Created">New</Tab>
                        <Tab value="WaitingForApplicant">Waiting</Tab>
                        <Tab value="Ready">Ready</Tab>
                        <Tab value="Approved">Approved</Tab>
                        <Tab value="Rejected">Rejected</Tab>
                    </TabList>

                    <DataGrid
                        items={sortedApplications}
                        columns={columns}
                        getRowId={item => item.id}
                        sortable={true}
                        sort={{
                            columnId: sortColumn,
                            direction: sortDirection
                        }}
                        onSortChange={(e, data) => {
                            setSortColumn(data.columnId);
                            setSortDirection(data.direction);
                        }}
                    >
                        <DataGridHeader className={classes.gridHeader}>
                            <DataGridRow>
                                {({ renderHeaderCell }) => (
                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                )}
                            </DataGridRow>
                        </DataGridHeader>
                        <DataGridBody>
                            {({ item, rowId }) => (
                                <DataGridRow
                                    key={rowId}
                                    onClick={e => handleAction('edit', item, e)}
                                    className={classes.row}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                            )}
                        </DataGridBody>
                    </DataGrid>
                    {isLoading && (
                        <div className="flex justify-center items-center p-8">
                            <Spinner size="large" />
                        </div>
                    )}
                </Card>
            </div>
        </>
    );
};

export default HomePage;