import React, { useCallback, useState } from "react";
import {
    Checkbox,
    Button,
    Text,
    makeStyles,
    tokens,
    Card
} from "@fluentui/react-components";
import {
    AlertRegular,
    ArrowSyncRegular,
    BuildingBankLink20Regular,
    LockClosedRegular,
    DocumentRegular,
    SaveRegular,
    PersonRegular,
    ShieldRegular,
    MailRegular
} from "@fluentui/react-icons";

// Our styles remain the same but let's adjust the Button styling since it's a Fluent UI component
const useStyles = makeStyles({
    container: {
        maxWidth: '900px',
        margin: '0 auto',
        padding: '32px',
    },
    header: {
        marginBottom: '40px',
        textAlign: 'center',
    },
    headerTitle: {
        fontSize: '28px',
        fontWeight: '600',
        color: tokens.colorBrandForeground1,
        marginBottom: '8px',
    },
    headerSubtitle: {
        color: tokens.colorNeutralForeground2,
        fontSize: '16px',
    },
    sectionsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '24px',
        marginBottom: '32px',
    },
    section: {
        background: tokens.colorNeutralBackground1,
        borderRadius: '8px',
        padding: '24px',
        boxShadow: tokens.shadow4,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
    },
    sectionTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: tokens.colorNeutralForeground1,
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    sectionIcon: {
        color: tokens.colorBrandForeground1,
    },
    checkboxList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'start',
        gap: '12px',
        padding: '8px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        },
    },
    checkboxLabel: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
    },
    labelText: {
        fontSize: '14px',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
    },
    labelDescription: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    }
});

const notificationSections = {
    applicant: {
        title: "Applicant Notifications",
        icon: <PersonRegular />,
        items: [
            { id: 1, event: "Customer Connected Bank", description: "Get notified when an applicant connects their bank account", isChecked: true },
            { id: 2, event: "Customer Disconnected Bank", description: "Notifications when an applicant disconnects their bank account", isChecked: true },
            { id: 3, event: "Customer Added Additional Contact", description: "Alerts when additional contacts are added to an application", isChecked: true },
        ]
    },
    documents: {
        title: "Document Notifications",
        icon: <DocumentRegular />,
        items: [
            { id: 4, event: "Customer Uploaded Bank Statement", description: "Receive notifications when bank statements are uploaded", isChecked: true },
            { id: 5, event: "Customer Uploaded Documents", description: "Alerts when any documents are uploaded", isChecked: true },
            { id: 6, event: "Document Verification Complete", description: "Notifications when document verification is completed", isChecked: true },
        ]
    },
    transactions: {
        title: "Transaction Notifications",
        icon: <BuildingBankLink20Regular />,
        items: [
            { id: 7, event: "Transaction Sync Complete", description: "Updates when transaction synchronization completes", isChecked: true },
            { id: 8, event: "Historic Transaction Sync", description: "Notifications about historic transaction retrieval", isChecked: true },
            { id: 9, event: "Transaction Analysis Complete", description: "Alerts when transaction analysis is finished", isChecked: true },
        ]
    },
    application: {
        title: "Application Status",
        icon: <AlertRegular />,
        items: [
            { id: 10, event: "Application Status Change", description: "Notifications when application status changes", isChecked: true },
            { id: 11, event: "Follow Up Request Submitted", description: "Alerts when follow-up information is requested", isChecked: true },
            { id: 12, event: "Application Assignment", description: "Notifications when an application is assigned to you", isChecked: true },
        ]
    },
};
export default function UserPreferences() {
    const styles = useStyles();
    const [sections, setSections] = useState(notificationSections);

    const toggleItem = useCallback((sectionKey, id) => {
        setSections(prev => ({
            ...prev,
            [sectionKey]: {
                ...prev[sectionKey],
                items: prev[sectionKey].items.map(item =>
                    item.id === id ? { ...item, isChecked: !item.isChecked } : item
                )
            }
        }));
    }, []);

    const handleSave = useCallback(() => {
        const selectedNotifications = Object.entries(sections).reduce((acc, [section, data]) => {
            const sectionNotifications = data.items
                .filter(item => item.isChecked)
                .map(item => ({ section, ...item }));
            return [...acc, ...sectionNotifications];
        }, []);

        console.log("Saved Notifications:", selectedNotifications);
        // TODO: API call to save preferences
    }, [sections]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Text className={styles.headerTitle}>User Preferences</Text>

            </div>

            <div className={styles.sectionsGrid}>
                {Object.entries(sections).map(([key, section]) => (
                    <Card key={key} className={styles.section}>
                        <div className={styles.sectionTitle}>
                            <span className={styles.sectionIcon}>{section.icon}</span>
                            {section.title}
                        </div>
                        <div className={styles.checkboxList}>
                            {section.items.map((item) => (
                                <div key={item.id} className={styles.checkboxWrapper}>
                                    <Checkbox
                                        checked={item.isChecked}
                                        onChange={() => toggleItem(key, item.id)}
                                    />
                                    <div className={styles.checkboxLabel}>
                                        <span className={styles.labelText}>{item.event}</span>
                                        <span className={styles.labelDescription}>{item.description}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                ))}
            </div>

            <div className={styles.buttonContainer}>
                <Button
                    appearance="primary"
                    icon={<SaveRegular />}
                    onClick={handleSave}
                >
                    Save Preferences
                </Button>
            </div>
        </div>
    );
}