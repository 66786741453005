import React, { useCallback, useEffect, useState, createContext, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TenantApiClient } from 'providers/TenantApiClient';
import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";
import { useAuth } from "react-oidc-context";
import useUserStore from "stores/userStore";
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import './App.css';
import 'react-phone-number-input/style.css';

import { FluentProvider, webLightTheme, webDarkTheme } from '@fluentui/react-components';
import { AppBar } from "./components/AppBar";
import { HeaderBar } from "./components/HeaderBar";
import { AppIcon } from "./components/AppIcon";
import { UserProfile } from "./components/UserProfile";
import { Settings } from 'components/Settings';
import useMessageStore from 'stores/messageStore';
import { DismissRegular } from "@fluentui/react-icons";

import {
  MessageBarGroup,
  MessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  Button,
  Link,
} from "@fluentui/react-components";

import lightBackground from 'assets/images/backgrounds/crm-light.png';
import darkBackground from 'assets/images/backgrounds/crm-dark.png';

// Import your routes/pages
import HomePage from './pages/Home';
import AdminMainPage from 'pages/Admin/Main';
import ApplicantPage from 'pages/Applicants/Applicant';
import FinicityMainPage from 'pages/Finicity/Main';
import RatePlansHomePage from 'pages/RatePlans/Home';
import BillingHomePage from 'pages/Billing/Home';
import ViewBillingPage from 'pages/Billing/View';
import TenantsHomePage from 'pages/Tenants/Home';
import useTenantStore from 'stores/tenantStore';

import { TenantProvider } from '../src/tenantContext';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';



window.PerfectScrollbar = PerfectScrollbar;

const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    position: 'relative',
    overflow: 'hidden',
  },
  lightBackground: {
    backgroundImage: `url(${lightBackground})`,
    backgroundPosition: "50%",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  darkBackground: {
    backgroundImage: `url(${darkBackground})`,
    backgroundPosition: "50%",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  sidebar: {
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    width: '72px',
    zIndex: 100,
  },
  header: {
    position: 'fixed',
    top: 0,
    left: '72px',
    right: 0,
    height: '42px',
    zIndex: 90,
  },
  main: {
    marginLeft: '72px',
    marginTop: '42px',
    padding: '20px',
    height: 'calc(100vh - 42px)',
    overflow: 'auto',
    position: 'relative',
  },
  messageGroup: {
    position: 'sticky',
    top: 0,
    zIndex: 80,
    marginBottom: '16px',
  }
});

function App() {
  const classes = useStyles();
  const [useDarkMode, setUseDarkMode] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const messageStore = useMessageStore();
  const tenantStore = useTenantStore();
  const auth = useAuth();

  const clearError = (id) => {
    messageStore.clearMessage(id);
  };

  const loadTenant = () => {
    try {
        const sessionStorageKey = Object.keys(sessionStorage).find(key =>
            key.includes('oidc.user')
        );

        if (!sessionStorageKey) {
            console.warn('OIDC user data not found in sessionStorage.');
            return;
        }

        const sessionData = JSON.parse(sessionStorage.getItem(sessionStorageKey));

        if (!sessionData?.profile) {
            console.warn('Invalid session data structure.');
            return;
        }

        const userEmail = sessionData.profile.email ?? sessionData.profile.sub;

        TenantApiClient.get('/api/tenants/info')
            .then((data) => {
                tenantStore.setTenant(data);

                const matchedUser = data.tenantUsers?.find(user =>
                    user.email?.toLowerCase() === userEmail?.toLowerCase()
                );

                if (matchedUser) {
                    useUserStore.getState().setUser({
                        email: matchedUser.email,
                        id: matchedUser.id,
                        mobilePhone: matchedUser.mobilePhone || '',
                        name: matchedUser.name || '',
                        roles: matchedUser.roles || [],
                        status: matchedUser.status || '',
                        title: matchedUser.title || null
                    });
                } else {
                    console.warn('No matching user found in tenant data.');
                }
            })
            .catch((error) => {
                console.error('Error loading tenant data:', error);
                messageStore.addAxiosError("Failed to load tenant data", error);
            });
    } catch (error) {
        console.error('Error accessing sessionStorage data:', error);
    }
};



  // Auth handling code remains the same
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return (
      <FluentProvider theme={webLightTheme}>
        <div>Loading...</div>
      </FluentProvider>
    );
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!isLoaded) {
    if (!auth.isAuthenticated) {
      auth.signinRedirect();
    } else {
      localStorage.setItem('token', auth?.user?.access_token ?? '');
      loadTenant();
      setIsLoaded(true);
    }
  }

  // Apollo client setup remains the same
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_TENANT_API + "/graphql",
  });

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  if (auth.isAuthenticated) {
    return (
      <FluentProvider theme={useDarkMode ? webDarkTheme : webLightTheme}>
        {isLoaded && (
          <ApolloProvider client={apolloClient}>
            <TenantProvider>
              <div className={mergeClasses(
                classes.root,
                useDarkMode ? classes.darkBackground : classes.lightBackground
              )}>
                <BrowserRouter>
                  {/* Modals */}
                  <UserProfile isOpen={isUserProfileOpen} onClose={() => setIsUserProfileOpen(false)} />
                  <Settings
                    isOpen={isSettingsOpen}
                    onClose={() => setIsSettingsOpen(false)}
                    useDarkMode={useDarkMode}
                    onSwitchDarkMode={() => setUseDarkMode(!useDarkMode)}
                  />

                  {/* Layout */}
                  <nav className={classes.sidebar}>
                    <AppBar />
                  </nav>

                  <header className={classes.header}>
                    <HeaderBar
                      onShowUserProfile={() => setIsUserProfileOpen(true)}
                      onShowSettings={() => setIsSettingsOpen(true)}
                    />
                  </header>

                  <main className={classes.main}>
                    {messageStore.messageCount > 0 && (
                      <MessageBarGroup className={classes.messageGroup}>
                        {messageStore.messages.map((message) => (
                          <MessageBar key={message.id} intent={message.intent ?? "error"}>
                            <MessageBarBody>
                              <MessageBarTitle>{message.title}</MessageBarTitle>
                              {message.message}
                            </MessageBarBody>
                            <MessageBarActions
                              containerAction={
                                <Button
                                  aria-label="dismiss"
                                  appearance="transparent"
                                  icon={<DismissRegular />}
                                  onClick={() => clearError(message.id)}
                                />
                              }
                            />
                          </MessageBar>
                        ))}
                      </MessageBarGroup>
                    )}

                    <Routes>
                      <Route path="/" element={<HomePage darkMode={useDarkMode} />} />
                      <Route path="/Applicant/:id" element={<ApplicantPage />} />
                      <Route path="/Admin" element={<AdminMainPage darkMode={useDarkMode} />} />
                      <Route path="/Billing" element={<BillingHomePage darkMode={useDarkMode} />} />
                      <Route path="/Billing/:id" element={<ViewBillingPage />} />
                      <Route path="/Tenants" element={<TenantsHomePage darkMode={useDarkMode} />} />
                      <Route path="/Finicity" element={<FinicityMainPage />} />
                    </Routes>
                  </main>
                </BrowserRouter>
              </div>
            </TenantProvider>
          </ApolloProvider>
        )}
      </FluentProvider>
    );
  }
}

export default App;