import React, { useState, useEffect } from "react";
import { makeStyles, tokens, mergeClasses } from "@fluentui/react-components";
import {
    Alert24Regular,
    AlertOff24Regular,
    InfoRegular,
    AlertUrgentRegular,
    AlertRegular,
    ArrowDown24Regular,
    ArrowSyncCircle24Regular
} from "@fluentui/react-icons";
import { InternalApiClient } from 'providers/InternalApiClient';
import useTenantStore from 'stores/tenantStore';
import useMessageStore from 'stores/messageStore';

const useStyles = makeStyles({
    notificationList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '8px',
    },
    notification: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px'
    },
    severityBadge: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: '500',
    },
    success: {
        backgroundColor: '#D1FADF',  // Light green background
        color: '#027A48'             // Dark green text
    },
    information: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorBrandForeground1,
    },
    warning: {
        backgroundColor: tokens.colorPaletteYellowBackground1,
        color: tokens.colorPaletteYellowForeground1,
    },
    error: {
        backgroundColor: tokens.colorPaletteRedBackground1,
        color: tokens.colorPaletteRedForeground1,
    },
    metaSection: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2
    },
    loadingSpinner: {
        animation: 'spin 1s linear infinite'
    },
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
    }
});

const getSeverityIcon = (severity) => {
    switch (severity?.toLowerCase().trim()) {
        case 'error': return <AlertUrgentRegular />;
        case 'warning': return <AlertRegular />;
        case 'success': return <InfoRegular />;
        default: return <InfoRegular />;
    }
};

export default function ActivityLog() {
    const classes = useStyles();
    const tenantStore = useTenantStore();
    const messageStore = useMessageStore();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 5;

    const fetchActivities = async (page = 1, append = false) => {
        try {
            setLoading(true);
            const response = await InternalApiClient.get(
                `/api/tenants/${tenantStore.id}/notifications?pageNumber=${page}&pageSize=${pageSize}`
            );
            setItems(prev => append ? [...prev, ...response] : response);
            setHasMore(response.length === pageSize);
        } catch (error) {
            setError('Failed to load activities');
          //  messageStore.addAxiosError('Error loading activities', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => { fetchActivities(); }, [tenantStore.id]);

    const handleLoadMore = () => {
        if (!loading && hasMore) {
            const nextPage = pageNumber + 1;
            setPageNumber(nextPage);
            fetchActivities(nextPage, true);
        }
    };

    return (
        <div className={classes.notificationList}>
            {items.map((item) => (
                <div key={item.id} className={classes.notification}>
                    <div className={classes.header}>
                        <div className={mergeClasses(
                            classes.severityBadge,
                            classes[item.severity.toLowerCase().trim()] || classes.information
                        )}>
                            {getSeverityIcon(item.severity)}
                            {item.severity}
                        </div>
                        
                        {item.applicationId === null ? (
                            <div>
                                <div className={classes.metaSection}>Tenant ID: {item.tenantId}</div>
                                <div className={classes.metaSection}>Timestamp: {new Date(item.timestamp).toLocaleString()}</div>
                            </div>
                        ) : (
                            <div>
                                <div className={classes.metaSection}>App ID: {item.applicationId}</div>
                                <div className={classes.metaSection}>Timestamp: {new Date(item.timestamp).toLocaleString()}</div>
                            </div>
                        )}
                    </div>
                    <div>{item.message}</div>
                </div>
            ))}

            {hasMore && (
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleLoadMore} disabled={loading} style={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
                        {loading ? <ArrowSyncCircle24Regular className={classes.loadingSpinner} /> : <ArrowDown24Regular />}
                    </button>
                </div>
            )}
        </div>
    );
}
