import React, { useCallback, useState } from "react";
import {
    Checkbox,
    Button,
    Text,
    makeStyles,
    tokens,
    Card
} from "@fluentui/react-components";
import {
    AlertRegular,
    ArrowSyncRegular,
    BuildingBankLink20Regular,
    LockClosedRegular,
    DocumentRegular,
    SaveRegular,
    PersonRegular,
    ShieldRegular,
    MailRegular,
    BuildingBankRegular,
    PaymentRegular
} from "@fluentui/react-icons";

// Our styles remain the same but let's adjust the Button styling since it's a Fluent UI component
const useStyles = makeStyles({
    container: {
        maxWidth: '900px',
        margin: '0 auto',
        padding: '32px',
    },
    header: {
        marginBottom: '40px',
        textAlign: 'center',
    },
    headerTitle: {
        fontSize: '28px',
        fontWeight: '600',
        color: tokens.colorBrandForeground1,
        marginBottom: '8px',
    },
    headerSubtitle: {
        color: tokens.colorNeutralForeground2,
        fontSize: '16px',
    },
    sectionsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '24px',
        marginBottom: '32px',
    },
    section: {
        background: tokens.colorNeutralBackground1,
        borderRadius: '8px',
        padding: '24px',
        boxShadow: tokens.shadow4,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
    },
    sectionTitle: {
        fontSize: '18px',
        fontWeight: '600',
        color: tokens.colorNeutralForeground1,
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    sectionIcon: {
        color: tokens.colorBrandForeground1,
    },
    checkboxList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'start',
        gap: '12px',
        padding: '8px',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        },
    },
    checkboxLabel: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
    },
    labelText: {
        fontSize: '14px',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
    },
    labelDescription: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '24px',
    }
});

const notificationSections = {
 
    loan: {
        title: "Loan Notifications",
        icon: <BuildingBankRegular />, // Replace with a relevant icon for loans
        items: [
            { id: 4, event: "Loan Application Status", description: "Receive updates on the status of your loan application", isChecked: true },
            { id: 5, event: "Loan Approval Notifications", description: "Notifications when your loan is approved or rejected", isChecked: true },
            { id: 6, event: "Payment Reminders", description: "Get reminders for upcoming loan payments and deadlines", isChecked: true },
        ]
    },
    documents: {
        title: "Document Notifications",
        icon: <DocumentRegular />,
        items: [
            { id: 7, event: "Document Upload Confirmation", description: "Receive confirmation when documents are successfully uploaded", isChecked: true },
            { id: 8, event: "Document Processing Updates", description: "Updates on the processing of loan-related documents", isChecked: true },
            { id: 9, event: "Document Expiration Alerts", description: "Get notified when important documents are about to expire", isChecked: false },
        ]
    },
    payments: {
        title: "Payment Notifications",
        icon: <PaymentRegular />, // Replace with a relevant icon for payments
        items: [
            { id: 10, event: "Payment Success Confirmation", description: "Get notified when a payment is successfully processed", isChecked: true },
            { id: 11, event: "Failed Payment Alerts", description: "Receive alerts if a payment fails or is overdue", isChecked: true },
            { id: 12, event: "Payment Reminder", description: "Reminders for upcoming payments due on your loans", isChecked: true },
        ]
    },
    alerts: {
        title: "System Alerts",
        icon: <AlertRegular />,
        items: [
            { id: 13, event: "Scheduled Maintenance Notifications", description: "Get notified about planned system maintenance and downtimes", isChecked: false },
            { id: 14, event: "Platform Feature Updates", description: "Notifications about new features and updates to the platform", isChecked: true },
            { id: 15, event: "Service Availability Alerts", description: "Real-time notifications regarding service availability or interruptions", isChecked: true },
        ]
    },
};


export default function TenantNotifications() {
    const styles = useStyles();
    const [sections, setSections] = useState(notificationSections);

    const toggleItem = useCallback((sectionKey, id) => {
        setSections(prev => ({
            ...prev,
            [sectionKey]: {
                ...prev[sectionKey],
                items: prev[sectionKey].items.map(item =>
                    item.id === id ? { ...item, isChecked: !item.isChecked } : item
                )
            }
        }));
    }, []);

    const handleSave = useCallback(() => {
        const selectedNotifications = Object.entries(sections).reduce((acc, [section, data]) => {
            const sectionNotifications = data.items
                .filter(item => item.isChecked)
                .map(item => ({ section, ...item }));
            return [...acc, ...sectionNotifications];
        }, []);

        console.log("Saved Notifications:", selectedNotifications);
        // TODO: API call to save preferences
    }, [sections]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <Text className={styles.headerTitle}>Tenant Notification Preferences</Text>

            </div>

            <div className={styles.sectionsGrid}>
                {Object.entries(sections).map(([key, section]) => (
                    <Card key={key} className={styles.section}>
                        <div className={styles.sectionTitle}>
                            <span className={styles.sectionIcon}>{section.icon}</span>
                            {section.title}
                        </div>
                        <div className={styles.checkboxList}>
                            {section.items.map((item) => (
                                <div key={item.id} className={styles.checkboxWrapper}>
                                    <Checkbox
                                        checked={item.isChecked}
                                        onChange={() => toggleItem(key, item.id)}
                                    />
                                    <div className={styles.checkboxLabel}>
                                        <span className={styles.labelText}>{item.event}</span>
                                        <span className={styles.labelDescription}>{item.description}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                ))}
            </div>

            <div className={styles.buttonContainer}>
                <Button
                    appearance="primary"
                    icon={<SaveRegular />}
                    onClick={handleSave}
                >
                    Save Preferences
                </Button>
            </div>
        </div>
    );
}