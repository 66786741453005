import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { TenantApiClient } from 'providers/TenantApiClient';
import { makeStyles, Text } from "@fluentui/react-components";
import {
    DocumentTextRegular,
    ListRegular,
    ArrowTrendingRegular,
    DocumentRegular,
    Money24Regular,
    Briefcase24Regular,
    Calendar24Regular
} from "@fluentui/react-icons";
import TransactionsPage from './Transactions';
import CashFlowPage from './CashFlow';
import ApplicantDashboardPage from './Dashboard';
import StatementsPage from './Statements';
import useMessageStore from 'stores/messageStore';

const useStyles = makeStyles({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
    },
    headerInfo: {
        padding: '10px 10px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(to right, rgba(59, 130, 246, 0.05), rgba(16, 185, 129, 0.05))',
    },
    navigationTabs: {
        padding: '8px,32px',
        backgroundColor: 'white',
        display: 'flex',
        gap: '8px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    navTab: {
        padding: '12px 24px',
        borderRadius: '8px',
        fontWeight: '500',
        color: '#64748b',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        border: 'none',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(59, 130, 246, 0.05)',
            color: '#3b82f6'
        },
        '&[data-selected=true]': {
            backgroundColor: '#3b82f6',
            color: 'white'
        }
    },

    contentArea: {
        flex: 1,
        overflow: 'auto',
        padding: '12px 16px'
    },
    infoRow: {
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
        marginTop: '16px'
    },
    infoItem: {
        display: 'flex',
        alignItems: 'center',

        color: '#64748b',
        '& svg': {
            color: '#3b82f6'
        }
    }
});

const amountFormat = (amount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(amount);
};

const Applicant = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { id } = useParams();
    const messageStore = useMessageStore();
    const [application, setApplication] = useState(null);
    const [selectedView, setSelectedView] = useState('dashboard');

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const data = await TenantApiClient.get(`/api/applications/${id}`);
                setApplication(data);
            } catch (error) {
                messageStore.addAxiosError("Failed to load application", error);
            }
        };

        fetchApplication();
    }, [id, messageStore]);

    if (!application) {
        return <div>Loading...</div>;
    }

    const renderContent = () => {
        switch (selectedView) {
            case 'transactions':
                return <TransactionsPage application={application} />;
            case 'cashflow':
                return <CashFlowPage application={application} />;
            case 'statements':
                return <StatementsPage application={application} />;
            case 'dashboard':
            default:
                return <ApplicantDashboardPage application={application} />;
        }
    };

    return (
        <div className="w-full min-h-screen ">
            {application !== null && (
                <div className="w-full h-full bg-white/80 backdrop-blur-md rounded-2xl min-h-[calc(100vh-40px)] shadow-2xl transition-all duration-200">
                    {/* Decorative header with gradient overlay */}
                    <div className="relative overflow-hidden rounded-t-2xl">
                        <div className="absolute inset-0 h-48 bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-blue-500/5">
                            <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(120,119,198,0.1),rgba(255,255,255,0))]" />
                        </div>

                        {/* Main content section */}
                        <div className="relative px-6">
                            <div className="flex items-start justify-between mb-8">
                                {/* Left side content */}
                                <div className="space-y-6">
                                    {/* Name and Status */}
                                    <div>
                                        <Text weight="bold" size={800} className="text-gray-900 mb-3">
                                            {`${application.primaryContact.firstName} ${application.primaryContact.lastName}`}
                                        </Text>
                                        <div className="flex items-center gap-3 mt-2">
                                            <div className="px-3 py-1 bg-gradient-to-r from-blue-500/10 to-blue-600/10 border border-blue-100 rounded-full flex items-center gap-2">
                                                <Briefcase24Regular className="w-4 h-4 text-blue-600" />
                                                <span className="text-sm font-medium text-blue-700">
                                                    {application.applicationType}
                                                </span>
                                            </div>
                                            <div className="px-3 py-1 bg-gradient-to-r from-purple-500/10 to-purple-600/10 border border-purple-100 rounded-full flex items-center gap-2">
                                                <span className="w-2 h-2 rounded-full bg-purple-500"></span>
                                                <span className="text-sm font-medium text-purple-700">
                                                    {application.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                 
                                    {/* Timeline Section */}
                                    <div className="flex items-center gap-4">
                                        {/* Right side - Loan Card (preserved) */}
                                        <div className="p-2 bg-white rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-shadow duration-200">
                                            <div className="flex items-center ">
                                                <div className="p-2 bg-green-50 rounded-full">
                                                    <Money24Regular className="w-5 h-5 text-green-600" />
                                                </div>
                                                <Text size={300} className="text-gray-600 font-medium p-1">Loan Consideration</Text>
                                            </div>
                                            <Text size={600} weight="semibold" className="text-gray-900">
                                                {amountFormat(application.loanConsiderationLow)} - {amountFormat(application.loanConsiderationHigh)}
                                            </Text>
                                        </div>
                                        <div className="p-3 bg-white rounded-lg shadow-sm border border-gray-100 flex items-center gap-3">
                                            <div className="p-2 bg-blue-50 rounded-full">
                                                <Calendar24Regular className="w-5 h-5 text-blue-600" />
                                            </div>
                                            <div>
                                                <Text size={200} className="text-gray-500">Created </Text>
                                                <Text size={300} weight="medium" className="text-gray-900">
                                                    {new Date(application.createdDate).toLocaleDateString(undefined, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })}
                                                </Text>
                                            </div>
                                        </div>
   
                                        <div className="p-3 bg-white rounded-lg shadow-sm border border-gray-100 flex items-center gap-3">
                                            <div className="p-2 bg-purple-50 rounded-full">
                                                <Calendar24Regular className="w-5 h-5 text-purple-600" />
                                            </div>
                                            <div>
                                                <Text size={200} className="text-gray-500">Updated </Text>
                                                <Text size={300} weight="medium" className="text-gray-900">
                                                    {new Date(application.updateWhen).toLocaleDateString(undefined, {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric'
                                                    })}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>

 
                            </div>

                            {/* Bottom gradient bar */}

                        </div>
                    </div>

                    {/* Navigation */}
                    <div className={classes.navigationTabs}>
                        <button
                            className={classes.navTab}
                            data-selected={selectedView === 'application'}
                            onClick={() => setSelectedView('application')}
                        >
                            <DocumentTextRegular />
                            Application
                        </button>
                        <button
                            className={classes.navTab}
                            data-selected={selectedView === 'transactions'}
                            onClick={() => setSelectedView('transactions')}
                        >
                            <ListRegular />
                            Transactions
                        </button>
                        <button
                            className={classes.navTab}
                            data-selected={selectedView === 'cashflow'}
                            onClick={() => setSelectedView('cashflow')}
                        >
                            <ArrowTrendingRegular />
                            Cash Flow
                        </button>
                        <button
                            className={classes.navTab}
                            data-selected={selectedView === 'statements'}
                            onClick={() => setSelectedView('statements')}
                        >
                            <DocumentRegular />
                            Statements
                        </button>
                    </div>

                    {/* Content Area */}
                    <div className={classes.contentArea}>
                        {renderContent()}
                    </div>
                </div>
            )}
        </div>
    );
}
 export default Applicant;
