import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import useUserStore from 'stores/userStore';
import useMessageStore from 'stores/messageStore';
import {
    PersonRegular,
    Mail24Regular,
    Phone24Regular,
    ShieldLockRegular,
    GlobePersonRegular,
    PenRegular
} from "@fluentui/react-icons";

const Profile = () => {
    const userStore = useUserStore();
    const messageStore = useMessageStore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const initialUserData = userStore;
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        profileImage: null
    });

    useEffect(() => {
        try {
            const [firstName = '', lastName = ''] = initialUserData.name?.split(' ') || ['', ''];
            setProfileData({
                firstName,
                lastName,
                email: initialUserData.email || '',
                mobilePhone: initialUserData.mobilePhone || '',
                profileImage: null
            });
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    }, [initialUserData]);

    const handleImageUpload = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileData((prev) => ({
                    ...prev,
                    profileImage: e.target.result
                }));
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleInputChange = useCallback((field, value) => {
        setProfileData((prev) => ({
            ...prev,
            [field]: value
        }));
    }, []);

    const handleSubmit = useCallback(() => {
        const updatedUserData = {
            ...userStore,
            name: `${profileData.firstName} ${profileData.lastName}`.trim(),
            email: profileData.email,
            mobilePhone: profileData.mobilePhone
        };

        userStore.setUser(updatedUserData);
        messageStore.setMessage('Profile updated successfully', 'success');
    }, [profileData, userStore, messageStore]);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center">Error loading profile data</div>;
    }

    return (
        <div className="max-w-6xl mx-auto p-3">
            <h1 className="text-3xl font-semibold mb-6 text-center text-blue-600">Lender Information</h1>


            <div className="flex flex-col items-center mb-8 relative">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                    id="imageUpload"
                />
                <label htmlFor="imageUpload" className="cursor-pointer">
                    {profileData.profileImage ? (
                        <img src={profileData.profileImage} alt="Profile" className="w-32 h-32 rounded-full object-cover" />
                    ) : (
                        <div className="w-32 h-32 bg-gray-200 flex items-center justify-center rounded-full">
                            <span className="text-gray-500">Upload Image</span>
                        </div>
                    )}
                </label>
            </div>

            <div className="flex">
                {/* Left Column - Personal Info */}
                <div className="flex-1 pr-8 border-r border-gray-200">
           
                    <div className="space-y-6">
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1"> Lender Company Name</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PersonRegular className="text-gray-400" />
                                </div>
                                <input
                                  
                                    onChange={e => handleInputChange('firstName', e.target.value)}
                                    placeholder="Name"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Website</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <GlobePersonRegular className="text-gray-400" />
                                </div>
                                <input
                                  
                                    onChange={e => handleInputChange('email', e.target.value)}
                                    placeholder="https://"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Application Greeting Message</label>
           
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                        <PenRegular className="text-gray-400" />
                                    </div>
                                    <textarea

                                        onChange={e => handleInputChange('email', e.target.value)}
                                        placeholder="Message"
                                        className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                    />
                                </div>
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">Application Thank You Message</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <PenRegular className="text-gray-400" />
                                </div>
                                <textarea

                                    onChange={e => handleInputChange('email', e.target.value)}
                                    placeholder="Message"
                                    className="pl-10 p-2 border rounded w-1/2 min-w-[300px] hover:border-blue-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-all"
                                />
                            </div>
                        </div>


                    </div>
                </div>

              
            </div>

            <div className="mt-8 pt-6 border-t border-gray-200">
                <button
                    className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
                    onClick={handleSubmit}
                >
                    Update Company Profile
                </button>
            </div>
        </div>

    );
};

export default Profile;