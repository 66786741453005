import React from 'react';
import {
    Dialog,
    DialogSurface,
    DialogBody,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Text,
    makeStyles,
    tokens,
} from "@fluentui/react-components";
import {
    PersonRegular,
    LocationRegular,
    MailRegular,
    PhoneRegular,
    NumberSymbolRegular,
    Building24Regular
} from "@fluentui/react-icons";

const useStyles = makeStyles({
    dialogSurface: {
        maxWidth: '1000px', // Increased from 600px
        width: '95vw',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.9) 100%)',
        backdropFilter: 'blur(10px)',
        borderRadius: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
    },
    dialogBody: {
        padding: '24px',
    },
    dialogTitle: {
        fontSize: '1.75rem',
        fontWeight: '700',
        background: 'linear-gradient(90deg, #2563EB 0%, #3B82F6 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: '24px',
        textAlign: 'center',
        letterSpacing: '-0.02em',
        padding: '16px 0',
        borderBottom: `2px solid ${tokens.colorNeutralStroke1}`,
    },
    sectionsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Two columns layout
        gap: '20px',
        marginBottom: '24px',
    },
    section: {
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
        },
    },
    fullWidthSection: {
        gridColumn: '1 / -1', // Span full width
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        marginBottom: '16px',
        paddingBottom: '12px',
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
    },
    sectionIcon: {
        color: tokens.colorBrandForeground1,
        fontSize: '24px',
    },
    sectionTitle: {
        fontSize: '1.2rem',
        fontWeight: '600',
        color: tokens.colorNeutralForeground1,
        letterSpacing: '-0.01em',
        background: 'linear-gradient(90deg, #1E40AF 0%, #3B82F6 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
    infoGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '16px',
    },
    infoItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        padding: '8px',
        borderRadius: '8px',
        transition: 'background-color 0.2s ease',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
        },
    },
    label: {
        fontSize: '0.875rem',
        color: tokens.colorNeutralForeground2,
        fontWeight: '600',
        textTransform: 'uppercase',
        letterSpacing: '0.03em',
    },
    value: {
        fontSize: '1rem',
        color: tokens.colorNeutralForeground1,
        fontWeight: '500',
        letterSpacing: '-0.01em',
        lineHeight: '1.4',
    },
    actions: {
        padding: '16px 24px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        borderTop: `1px solid ${tokens.colorNeutralStroke1}`,
    },
    closeButton: {
        backgroundColor: tokens.colorBrandBackground,
        color: 'white',
        fontWeight: '600',
        padding: '8px 24px',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
            transform: 'translateY(-1px)',
        },
    },
});

const AccountVerificationDialog = ({ data, showDialog, onClose }) => {
    const classes = useStyles();

    if (!showDialog) return null;

    const holder = data?.accountOwnerHolders.holders?.[0];
    const primaryAddress = holder?.addresses?.find(addr => addr.type === 'PRIMARY');
    const mailingAddress = holder?.addresses?.find(addr => addr.type === 'MAILING');
    const primaryEmail = holder?.emails?.find(email => email.isPrimary);
    const primaryPhone = holder?.phones?.find(phone => phone.type === 'HOME');
    const paymentInfo = data?.paymentInstructions?.paymentInstruction?.[0];

    return (
        <Dialog open={showDialog}>
            <DialogSurface className={classes.dialogSurface}>
                <DialogBody className={classes.dialogBody}>
                    <DialogTitle className={classes.dialogTitle}>
                        Account Verification Details
                    </DialogTitle>
                    <DialogContent>
                        <div className={classes.sectionsContainer}>
                            {/* Personal Information Section */}
                            <div className={classes.section}>
                                <div className={classes.sectionHeader}>
                                    <PersonRegular className={classes.sectionIcon} />
                                    <Text className={classes.sectionTitle}>Personal Information</Text>
                                </div>
                                <div className={classes.infoGrid}>
                                    <div className={classes.infoItem}>
                                        <Text className={classes.label}>Full Name</Text>
                                        <Text className={classes.value}>{holder?.ownerName || 'N/A'}</Text>
                                    </div>
                                    <div className={classes.infoItem}>
                                        <Text className={classes.label}>Classification</Text>
                                        <Text className={classes.value}>{holder?.nameClassification || 'N/A'}</Text>
                                    </div>
                                    
                                </div>
                            </div>

                            {/* Account Details */}
                            <div className={classes.section}>
                                <div className={classes.sectionHeader}>
                                    <Building24Regular className={classes.sectionIcon} />
                                    <Text className={classes.sectionTitle}>Account Details</Text>
                                </div>
                                <div className={classes.infoGrid}>
                                    <div className={classes.infoItem}>
                                        <Text className={classes.label}>Account Number</Text>
                                        <Text className={classes.value}>
                                            {paymentInfo?.accountNumber || 'N/A'}
                                        </Text>
                                    </div>
                                    <div className={classes.infoItem}>
                                        <Text className={classes.label}>Routing Number</Text>
                                        <Text className={classes.value}>
                                            {paymentInfo?.descriptors?.[0]?.value || 'N/A'}
                                        </Text>
                                    </div>
                                   
                                </div>
                            </div>

                            {/* Address Section - Full Width */}
                            <div className={`${classes.section} ${classes.fullWidthSection}`}>
                                <div className={classes.sectionHeader}>
                                    <LocationRegular className={classes.sectionIcon} />
                                    <Text className={classes.sectionTitle}>Addresses</Text>
                                </div>
                                <div className={classes.infoGrid}>
                                    {primaryAddress && (
                                        <div className={classes.infoItem}>
                                            <Text className={classes.label}>Primary Address</Text>
                                            <Text className={classes.value}>
                                                {`${primaryAddress.line1}, ${primaryAddress.city}, ${primaryAddress.state} ${primaryAddress.postalCode}`}
                                            </Text>
                                        </div>
                                    )}
                                    {mailingAddress && (
                                        <div className={classes.infoItem}>
                                            <Text className={classes.label}>Mailing Address</Text>
                                            <Text className={classes.value}>
                                                {`${mailingAddress.line1}, ${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.postalCode}`}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Contact Information - Full Width */}
                            <div className={`${classes.section} ${classes.fullWidthSection}`}>
                                <div className={classes.sectionHeader}>
                                    <MailRegular className={classes.sectionIcon} />
                                    <Text className={classes.sectionTitle}>Contact Information</Text>
                                </div>
                                <div className={classes.infoGrid}>
                                    {holder?.emails?.map((email, index) => (
                                        <div key={index} className={classes.infoItem}>
                                            <Text className={classes.label}>{`Email (${email.emailType})`}</Text>
                                            <Text className={classes.value}>{email.email}</Text>
                                        </div>
                                    ))}
                                    {holder?.phones?.map((phone, index) => (
                                        <div key={index} className={classes.infoItem}>
                                            <Text className={classes.label}>{`Phone (${phone.type})`}</Text>
                                            <Text className={classes.value}>{phone.phone}</Text>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button
                            appearance="primary"
                            className={classes.closeButton}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default AccountVerificationDialog;