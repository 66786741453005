import React, { useState } from 'react';
import { Card, makeStyles } from "@fluentui/react-components";
import AdminNav from './Nav';
import ActivityLog from "./Tabs/ActivityLog";
import Notifications from "./Tabs/Notifications";
import Profile from "./Tabs/Profile";
import Security from "./Tabs/Security";
import UserPreferences from "./Tabs/UserPreferences";
import TenantUsers from "./Tabs/TenantUsers";
import Branding from "./Tabs/Branding";
import ApplicantConfig from "./Tabs/ApplicantConfig";
import TenantEmails from "./Tabs/EmailTemplates";
import CompanyDocuments from "./Tabs/CompanyDocuments";
import TenantNotifications from "./Tabs/LenderNotifications";
import Payments from "./Tabs/Payments";

const useStyles = makeStyles({
    card: {
        margin: "20px",
        maxWidth: "1500px",
        backgroundColor: "rgba(255, 255, 255, 0.98)",
        borderRadius: "12px",
        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.05)",
        backdropFilter: "blur(8px)",
        border: "1px solid rgba(255, 255, 255, 0.18)",
        padding: "24px",
        transition: "all 0.2s ease-in-out",
        position: "relative",
        overflow: "hidden",

        // Subtle gradient overlay
        "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "linear-gradient(90deg, rgba(59, 130, 246, 0.5), rgba(147, 51, 234, 0.5))",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
        },

        // Subtle inner shadow for depth
        "&::after": {
            content: '""',
            position: "absolute",
            inset: 0,
            borderRadius: "12px",
            padding: "1px",
            background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))",
            WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
            WebkitMaskComposite: "xor",
            maskComposite: "exclude",
            pointerEvents: "none",
        }
    }
});

function getPageContent(pageIndex) {
    switch (pageIndex) {
        case "Profile": return <Profile />;
        case "Security": return <Security />;
        case "Personal Notifications": return <Notifications />;
        case "User Preferences": return <UserPreferences />;
        case "Tenant Users": return <TenantUsers />;
        case "Branding": return <Branding />;
        case "Lender Notifications": return <TenantNotifications />;
        case "Tenant Emails": return <TenantEmails />;
        case "Activity Log": return <ActivityLog />;     
        default: return <div>Select a setting to configure</div>;
    }
}

const AdminMainPage = () => {
    const [currentPage, setCurrentPage] = useState("Profile");
    const styles = useStyles();

    return (
        <div className="twoColumnContainer">
            <nav className="nav">
                <AdminNav
                    currentPage={currentPage}
                    setCurrentPage={(page) => setCurrentPage(page)}
                />
            </nav>
            <div className="body">
                <Card className={styles.card}>
                    {getPageContent(currentPage)}
                </Card>
            </div>
        </div>
    );
};

export default AdminMainPage;