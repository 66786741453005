import React, { useState, useEffect } from 'react';
import { makeStyles, tokens, mergeClasses } from "@fluentui/react-components";
import {
    Alert24Regular,
    AlertOff24Regular,
    ArrowDown24Regular,
    ArrowSyncCircle24Regular,
    InfoRegular,
    AlertUrgentRegular,
    AlertRegular
} from "@fluentui/react-icons";
import { Widget } from 'components/Widget';
import { InternalApiClient } from 'providers/InternalApiClient';
import useTenantStore from 'stores/tenantStore';
import useMessageStore from 'stores/messageStore';

const useStyles = makeStyles({
    emptyState: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '32px 16px',
        color: tokens.colorNeutralForeground3,
        textAlign: 'center',
        height: '100%',
        minHeight: '200px',
    },
    emptyIcon: {
        fontSize: '32px',
        marginBottom: '12px',
    },
    emptyText: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    notificationList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '8px',
        maxHeight: '500px',
        overflowY: 'auto',
    },
    notification: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s ease',
        '&:hover': {
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '12px',
    },
    headerLeft: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    headerRight: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground3,
    },
    severityBadge: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        padding: '4px 8px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: '500',
    },
    success: {
        backgroundColor: '#D1FADF',  // Light green background
        color: '#027A48'             // Dark green text
    },
    information: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorBrandForeground1,
    },
    warning: {
        backgroundColor: tokens.colorPaletteYellowBackground1,
        color: tokens.colorPaletteYellowForeground1,
    },
    error: {
        backgroundColor: tokens.colorPaletteRedBackground1,
        color: tokens.colorPaletteRedForeground1,
    },
    messageSection: {
        fontSize: '14px',
        color: tokens.colorNeutralForeground1,
        padding: '4px 0',
    },
    metaSection: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px',
        borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
        paddingTop: '8px',
    },
    metaItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    metaLabel: {
        fontSize: '11px',
        color: tokens.colorNeutralForeground3,
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
    },
    metaValue: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground1,
        fontFamily: 'monospace',
    },
    loadMore: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px',
        color: tokens.colorBrandForeground1,
        cursor: 'pointer',
        borderRadius: '4px',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackground2,
        },
    },
    loadingSpinner: {
        animation: 'spin 1s linear infinite',
    },
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
    }
});

const getSeverityIcon = (severity) => {
    switch (severity?.toLowerCase()) {
        case 'error':
            return <AlertUrgentRegular />;
        case 'warning':
            return <AlertRegular />;
        default:
            return <InfoRegular />;
    }
};

const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

const NotificationsWidget = () => {
    const classes = useStyles();
    const tenantStore = useTenantStore();
    const messageStore = useMessageStore();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 5;

    const fetchNotifications = async (page = 1, append = false) => {
        try {
            setLoading(true);
            setError(null);
            const response = await InternalApiClient.get(
                `/api/tenants/${tenantStore.id}/notifications?pageNumber=${page}&pageSize=${pageSize}`
            );

            if (append) {
                setNotifications(prev => [...prev, ...response]);
            } else {
                setNotifications(response);
            }

            setHasMore(response.length === pageSize);
        } catch (error) {
            setError('Failed to load notifications');
           // messageStore.addAxiosError('Error loading notifications', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [tenantStore.id]);

    const handleLoadMore = () => {
        if (!loading && hasMore) {
            const nextPage = pageNumber + 1;
            setPageNumber(nextPage);
            fetchNotifications(nextPage, true);
        }
    };

    return (
        <Widget
            image={<Alert24Regular />}
            title="Recent Notifications"
            widgetClass={classes.widget}
        >
            {notifications.length === 0 && !loading ? (
                <div className={classes.emptyState}>
                    <AlertOff24Regular className={classes.emptyIcon} />
                    <div className={classes.emptyText}>
                        No new notifications
                    </div>
                </div>
            ) : (
                <>
                    <div className={classes.notificationList}>
                        {notifications.map((notification) => (
                            <div key={notification.rowKey} className={classes.notification}>
                                <div className={classes.header}>
                                    <div className={classes.headerLeft}>
                                        <div className={mergeClasses(
                                            classes.severityBadge,
                                            classes[notification.severity.toLowerCase().trim()]

                                        )}>
                                            {getSeverityIcon(notification.severity)}
                                            {notification.severity}
                                        </div>
                                       
                                    </div>
                                    <div className={classes.headerRight}>
                                        {formatTimestamp(notification.timestamp)}
                                    </div>
                                </div>

                                <div className={classes.messageSection}>
                                    {notification.message}
                                </div>

                                <div className={classes.metaSection}>
                                    {notification.applicationId && (
                                        <div className={classes.metaItem}>
                                            <span className={classes.metaLabel}>App ID:</span>
                                            <span className={classes.metaValue}>{notification.applicationId}</span>
                                        </div>
                                    )}
                                    <div className={classes.metaItem}>
                                        <span className={classes.metaLabel}>Type:</span>
                                        <span className={classes.metaValue}>{notification.notificationType}</span>
                                    </div>
                                    {notification.tenantId && (
                                        <div className={classes.metaItem}>
                                            <span className={classes.metaLabel}>Tenant:</span>
                                            <span className={classes.metaValue}>{notification.tenantId}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    {hasMore && (
                        <div
                            className={classes.loadMore}
                            onClick={handleLoadMore}
                        >
                            {loading ? (
                                <ArrowSyncCircle24Regular className={classes.loadingSpinner} />
                            ) : (
                                <>
                                    <ArrowDown24Regular />
                                    <span>Load more</span>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </Widget>
    );
};

export default NotificationsWidget;