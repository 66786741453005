import React from 'react';
import {
    NavDrawer,
    NavDrawerBody,
    NavItem,
    NavSectionHeader,
} from "@fluentui/react-nav-preview";
import { makeStyles } from "@fluentui/react-components";
import {
    Alert20Regular,
    DataBarVertical20Regular,
    Document20Regular,
    DocumentText20Regular,
    List20Regular,
    LockShield20Regular,
    Mail20Regular,
    MoneyCalculator20Regular,
    Payment20Regular,
    People20Regular,
    PeopleAdd20Regular,
    PeopleSettings20Regular,
    Person20Regular,
    Settings20Regular,
    Tag20Regular,
} from "@fluentui/react-icons";

const useClasses = makeStyles({
    drawer: {
        height: "100vh",
        backgroundColor: "white",
        border: "none",
        boxShadow: "1px 0 0 rgba(0, 0, 0, 0.05)",
    }
});

const AdminNav = ({ currentPage, setCurrentPage }) => {
    const classes = useClasses();

    function onSelect(event, data) {
        setCurrentPage(data.value);
    }

    return (
        <NavDrawer
            className={classes.drawer}
            defaultSelectedValue={currentPage}
            open={true}
            type={"inline"}
            size={"medium"}
            onNavItemSelect={onSelect}
        >
            <NavDrawerBody>
                <NavSectionHeader>Personal Settings</NavSectionHeader>
                <NavItem icon={<Person20Regular />} value="Profile">Profile</NavItem>
                <NavItem icon={<LockShield20Regular />} value="Security">Security</NavItem>
                <NavItem icon={<Alert20Regular />} value="Personal Notifications">Notifications</NavItem>
                <NavItem icon={<Settings20Regular />} value="User Preferences">User Preferences</NavItem>

                <NavSectionHeader>Lender Settings</NavSectionHeader>
                <NavItem icon={<People20Regular />} value="Tenant Users">Users</NavItem>
                <NavItem icon={<Tag20Regular />} value="Branding">Branding</NavItem>
                <NavItem icon={<PeopleSettings20Regular />} value="Applicant Configuration">Applicant Configuration</NavItem>
                <NavItem icon={<Alert20Regular />} value="Lender Notifications">Notifications</NavItem>
                <NavItem icon={<Mail20Regular />} value="Tenant Emails">Email Templates</NavItem>
                <NavItem icon={<Document20Regular />} value="Company Documents">Company Documents</NavItem>
                <NavItem icon={<Payment20Regular />} value="Payments">Payments</NavItem>
                <NavItem icon={<DataBarVertical20Regular />} value="Analytics">Analytics</NavItem>
                <NavItem icon={<PeopleAdd20Regular />} value="Bulk Applicants Import">Bulk Applicants Import</NavItem>

                <NavSectionHeader>Billing</NavSectionHeader>
                <NavItem icon={<DocumentText20Regular />} value="Billing">Billing</NavItem>
                <NavItem icon={<MoneyCalculator20Regular />} value="Pricing">Pricing</NavItem>
                <NavItem icon={<List20Regular />} value="Activity Log">Activity Log</NavItem>
            </NavDrawerBody>
        </NavDrawer>
    );
};

export default AdminNav;