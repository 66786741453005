import React, { useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import useUserStore from 'stores/userStore';
import useMessageStore from 'stores/messageStore';

const ResetPassword = () => {
    const userStore = useUserStore();
    const messageStore = useMessageStore();
    const navigate = useNavigate();
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [loading, setLoading] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [showPassword, setShowPassword] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const togglePasswordVisibility = (field) => {
        setShowPassword(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleInputChange = useCallback((field, value) => {
        setPasswordData(prev => ({
            ...prev,
            [field]: value
        }));

        if (field === 'newPassword') {
            setPasswordValid(validatePassword(value));
        }

        if (field === 'confirmPassword') {
            setPasswordMatch(value === passwordData.newPassword);
        }
    }, [passwordData.newPassword]);

    const handleSubmit = useCallback(async () => {
        if (!passwordMatch) {
            messageStore.setMessage('Passwords do not match.', 'error');
            return;
        }

        if (!passwordValid) {
            messageStore.setMessage('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.', 'error');
            return;
        }

        setLoading(true);
        try {
            await userStore.resetPassword(passwordData.currentPassword, passwordData.newPassword);
            messageStore.setMessage('Password reset successfully.', 'success');
            navigate('/profile');
        } catch (error) {
            messageStore.setMessage('Failed to reset password.', 'error');
        } finally {
            setLoading(false);
        }
    }, [passwordData, userStore, messageStore, navigate, passwordValid, passwordMatch]);

    return (
        <div className="max-w-lg mx-auto p-6">
            <h1 className="text-3xl font-semibold mb-6 text-center">Reset Password</h1>

            <div className="grid grid-cols-1 gap-6">
                {/*<div className="flex flex-col gap-2">*/}
                {/*    <label className="text-sm font-medium text-gray-700">Current Password</label>*/}
                {/*    <div className="relative">*/}
                {/*        <input*/}
                {/*            type={showPassword.currentPassword ? 'text' : 'password'}*/}
                {/*            className="h-11 rounded-lg border px-4 w-full"*/}
                {/*            value={passwordData.currentPassword}*/}
                {/*            onChange={e => handleInputChange('currentPassword', e.target.value)}*/}
                {/*        />*/}
                {/*        <button*/}
                {/*            type="button"*/}
                {/*            className="absolute right-3 top-3 text-gray-500"*/}
                {/*            onClick={() => togglePasswordVisibility('currentPassword')}*/}
                {/*        >*/}
                {/*            {showPassword.currentPassword ? '👁️' : '👁️‍🗨️'}*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium text-gray-700">New Password</label>
                    <div className="relative">
                        <input
                            type={showPassword.newPassword ? 'text' : 'password'}
                            className="h-11 rounded-lg border px-4 w-full"
                            value={passwordData.newPassword}
                            onChange={e => handleInputChange('newPassword', e.target.value)}
                        />
                        <button
                            type="button"
                            className="absolute right-3 top-3 text-gray-500"
                            onClick={() => togglePasswordVisibility('newPassword')}
                        >
                            {showPassword.newPassword ? '👁️' : '👁️‍🗨️'}
                        </button>
                    </div>
                    {!passwordValid && passwordData.newPassword && <p className="text-red-500 text-sm">Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.</p>}
                </div>

                <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium text-gray-700">Confirm New Password</label>
                    <div className="relative">
                        <input
                            type={showPassword.confirmPassword ? 'text' : 'password'}
                            className="h-11 rounded-lg border px-4 w-full"
                            value={passwordData.confirmPassword}
                            onChange={e => handleInputChange('confirmPassword', e.target.value)}
                        />
                        <button
                            type="button"
                            className="absolute right-3 top-3 text-gray-500"
                            onClick={() => togglePasswordVisibility('confirmPassword')}
                        >
                            {showPassword.confirmPassword ? '👁️' : '👁️‍🗨️'}
                        </button>
                    </div>
                    {!passwordMatch && passwordData.confirmPassword && <p className="text-red-500 text-sm">Passwords do not match.</p>}
                </div>

                <button
                    className="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg"
                    onClick={handleSubmit}
                    disabled={loading || !passwordValid || !passwordMatch}
                >
                    {loading ? 'Resetting...' : 'Reset Password'}
                </button>
            </div>
        </div>
    );
};

export default ResetPassword;
