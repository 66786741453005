import {
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    TableCellLayout,
    makeStyles,
    tokens,
    Button,
    Badge,
    Card,
    Text
} from "@fluentui/react-components";
import React, { useMemo } from "react";
import useTenantStore from 'stores/tenantStore';
import {
    PersonRegular,
    MailRegular,
    PhoneRegular,
    ShieldLockRegular,
    EditRegular,
    DeleteRegular
} from "@fluentui/react-icons";

const useStyles = makeStyles({
    container: {
        padding: "24px",
        maxWidth: "1200px",
        margin: "0 auto",
    },
    header: {
        marginBottom: "24px",
    },
    headerTitle: {
        fontSize: "24px",
        fontWeight: "600",
        color: tokens.colorNeutralForeground1,
        marginBottom: "8px",
    },
    headerSubtitle: {
        color: tokens.colorNeutralForeground2,
    },
    rolesBadgeContainer: {
        display: "flex",
        gap: "4px",
        flexWrap: "wrap",
    },
    roleBadge: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorBrandForeground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
        textTransform: "capitalize",
    },
    dataGrid: {
        backgroundColor: tokens.colorNeutralBackground1,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        borderRadius: "8px",
        overflow: "hidden",
    },
    gridHeader: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    actionButton: {
        minWidth: "32px",
        height: "32px",
        padding: "0 8px",
    },
    actionButtonsContainer: {
        display: "flex",
        gap: "8px",
    },
    statusActive: {
        color: tokens.colorPaletteGreenForeground1,
        backgroundColor: tokens.colorPaletteGreenBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    },
    statusInactive: {
        color: tokens.colorPaletteRedForeground1,
        backgroundColor: tokens.colorPaletteRedBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    }
});

export default function TenantUsers() {
    const styles = useStyles();
    const tenantStore = useTenantStore();

    const columns = useMemo(() => [
        createTableColumn({
            columnId: "name",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <PersonRegular />
                    Name
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <div>
                        <div>{item.name}</div>
                        {item.title && (
                            <div style={{ fontSize: "12px", color: tokens.colorNeutralForeground2 }}>
                                {item.title}
                            </div>
                        )}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "email",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <MailRegular />
                    Email
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <Text>{item.email}</Text>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "mobilePhone",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <PhoneRegular />
                    Phone
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <Text>{item.mobilePhone || "-"}</Text>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "roles",
            renderHeaderCell: () => (
                <TableCellLayout>
                    <ShieldLockRegular />
                    Roles
                </TableCellLayout>
            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <div className={styles.rolesBadgeContainer}>
                        {item.roles?.map((role, index) => (
                            <span key={index} className={styles.roleBadge}>
                                {role}
                            </span>
                        ))}
                    </div>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "status",
            renderHeaderCell: () => "Status",
            renderCell: (item) => (
                <TableCellLayout>
                    <span className={item.status === "Active" ? styles.statusActive : styles.statusInactive}>
                        {item.status || "Inactive"}
                    </span>
                </TableCellLayout>
            ),
        }),
        createTableColumn({
            columnId: "actions",
            renderHeaderCell: () => "Actions",
            renderCell: (item) => (
                <TableCellLayout>
                    <div className={styles.actionButtonsContainer}>
                        <Button
                            icon={<EditRegular />}
                            appearance="subtle"
                            className={styles.actionButton}
                            title="Edit user"
                        />
                        <Button
                            icon={<DeleteRegular />}
                            appearance="subtle"
                            className={styles.actionButton}
                            title="Delete user"
                        />
                    </div>
                </TableCellLayout>
            ),
        }),
    ], [styles]);

    return (
        <div >
            <div className={styles.header}>
                <Text className={styles.headerTitle}>Tenant Users</Text>

            </div>

            <Card>
                <DataGrid
                    items={tenantStore.tenantUsers}
                    columns={columns}
                    sortable
                    getRowId={item => item.id}
                    className={styles.dataGrid}
                >
                    <DataGridHeader className={styles.gridHeader}>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>
                                    {renderHeaderCell()}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody>
                        {({ item, rowId }) => (
                            <DataGridRow key={rowId}>
                                {({ renderCell }) => (
                                    <DataGridCell>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </Card>
        </div>
    );
}