import React from 'react';
import { makeStyles, tokens } from "@fluentui/react-components";
import { useQuery } from '@apollo/client';
import { CalculatorRegular } from "@fluentui/react-icons";
import { Widget } from 'components/Widget';
import { Card, Text } from "@fluentui/react-components";
import { GET_TRANSACTION_TOTALS } from './queries';
import accountStore from 'stores/accountStore';
import useTimeframeStore from 'stores/TimeframeStore';

const useStyles = makeStyles({
    wrapper: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)'
        }
    },
    widget: {
        padding: '24px',
        minHeight: '300px',
        textAlign: 'center'
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '24px',
        margin: '24px 0'
    },
    statCard: {
        padding: '20px',
        borderRadius: '12px',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)'
        }
    },
    statLabel: {
        fontSize: '0.875rem',
        color: tokens.colorNeutralForeground2,
        marginBottom: '8px'
    },
    statValue: {
        fontSize: '1.5rem',
        fontWeight: '600',
        color: tokens.colorNeutralForeground1,
        lineHeight: '1.2'
    },
    iconWrapper: {
        fontSize: '24px',
        color: tokens.colorBrandBackground,
        marginBottom: '16px'
    }
});

const StatCard = ({ label, value, formatter }) => {
    const classes = useStyles();
    const displayValue = formatter ? formatter(value) : value;

    return (
        <Card className={classes.statCard}>
            <Text className={classes.statLabel}>{label}</Text>
            <Text className={classes.statValue}>{displayValue}</Text>
        </Card>
    );
};

const formatCurrency = (value) => {
    if (value === null || value === undefined) return '$0.00';
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

const formatNumber = (value) => {
    if (value === null || value === undefined) return '0';
    return value.toLocaleString('en-US');
};

const DashboardTotals = ({ application }) => {
    const classes = useStyles();
    const { selectedAccounts } = accountStore(); 
    const { timeRange } = useTimeframeStore();

    const { loading, error, data } = useQuery(GET_TRANSACTION_TOTALS, {
        variables: {
            applicationId: application.id,
            contactId: application.primaryContact.contactId,
            accountIds: selectedAccounts,
            startDate: calculateStartDate(timeRange), // Use the shared timeRange
            endDate: new Date()
        },
        onCompleted: (data) => {
            
        },
        onError: (error) => {
 
        }
    });

    if (loading) {
        return (
            <div className={classes.wrapper}>
                <Widget
                    widgetClass={classes.widget}
                    image={<CalculatorRegular className={classes.iconWrapper} />}
                    title="Transaction Totals"
                >
                    <div>Loading transaction data...</div>
                </Widget>
            </div>
        );
    }

    if (error) {
        return (
            <div className={classes.wrapper}>
                <Widget
                    widgetClass={classes.widget}
                    image={<CalculatorRegular className={classes.iconWrapper} />}
                    title="Transaction Totals"
                >
                    <div>Error loading transaction data: {error.message}</div>
                </Widget>
            </div>
        );
    }

    const totals = data?.transactionTotals || {
        totalTransactions: 0,
        totalSpending: 0,
        totalDeposits: 0,
        totalPayments: 0,
        averageDailyBalance: 0,
        daysWithNegativeBalance: 0
    };

    const stats = [
        {
            label: 'Total Transactions',
            value: totals.totalTransactions || 0,
            formatter: formatNumber
        },
        {
            label: 'Total Deposits Made',
            value: totals.totalDeposits || 0,
            formatter: formatNumber
        },
        {
            label: 'Total Payments Made',
            value: totals.totalPayments || 0,
            formatter: formatNumber
        },
        {
            label: 'Total Spending',
            value: totals.totalSpending || 0,
            formatter: formatCurrency
        },
        {
            label: 'Average Daily Balance',
            value: totals.averageDailyBalance || 0,
            formatter: formatCurrency
        },
        {
            label: 'Days with Negative Balance',
            value: totals.daysWithNegativeBalance || 0,
            formatter: formatNumber
        }
    ];

    return (
        <div className={classes.wrapper}>
            <Widget
                widgetClass={classes.widget}
                image={<CalculatorRegular className={classes.iconWrapper} />}
                title="Transaction Totals"
            >
                <div className={classes.grid}>
                    {stats.map((stat, index) => (
                        <StatCard
                            key={index}
                            label={stat.label}
                            value={stat.value}
                            formatter={stat.formatter}
                        />
                    ))}
                </div>
            </Widget>
        </div>
    );
};
const calculateStartDate = (timeRange) => {
    const date = new Date();
    date.setDate(date.getDate() - timeRange);
    return date;
};
export default DashboardTotals;