import React, { useCallback, useMemo, useState } from 'react';
import { gql, useQuery } from "@apollo/client";
import { subDays, format } from "date-fns";
import DateRangeSelectDialog from "../../dialogs/DateRangeSelect";
import useAccountStore from 'stores/accountStore';
import _ from 'lodash';
import { DonutChart } from "@fluentui/react-charting";
import {
    createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader,
    DataGridHeaderCell, DataGridRow, makeStyles, mergeClasses, SearchBox,
    Text, TableCellLayout, Tag, TagGroup, tokens,
    Toolbar, ToolbarGroup
} from "@fluentui/react-components";
import {
    ChevronDownRegular, ChevronRightRegular, SearchRegular,
    ArrowSyncCircleRegular,
    CalendarSearchRegular
} from "@fluentui/react-icons";
import useTimeframeStore from 'stores/TimeframeStore';

// Chart data processing hook
const useChartData = (transactions = [], application) => {
    return useMemo(() => {
        const processedTransactions = transactions.map(transaction => {
            const isPlaidAccount = application?.primaryContact?.plaidLink?.items?.some(item =>
                item.accounts?.some(acc => acc.id === transaction.accountId)
            );

            let processedAmount = transaction.amount;
            if (isPlaidAccount) {
                // For Plaid accounts: Keep the sign as is (positive = expense)
                processedAmount = transaction.amount;
            } else {
                // For Finicity accounts: Invert the sign (negative = expense)
                processedAmount = -transaction.amount;
            }

            return {
                ...transaction,
                processedAmount
            };
        });

        // Group by category using processed amounts
        const grouped = _.chain(processedTransactions)
            .groupBy('category')
            .map((items, category) => ({
                name: category || 'Uncategorized',
                value: Math.abs(_.sumBy(items, 'processedAmount')),
                count: items.length,
                transactions: items
            }))
            .sortBy(group => -group.value)
            .value();

        const total = Math.abs(_.sumBy(processedTransactions, 'processedAmount'));

        return {
            chart: grouped,
            total,
            transactions: processedTransactions
        };
    }, [transactions, application]);
};


// Chart section component
const ChartSection = React.memo(({ depositsData, expensesData, classes }) => {
    // Format currency values
    const formatCurrency = (value) =>
        Math.abs(value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });

    // Transform data for charts
    const formatChartData = (data, isExpense) => {
        const colors = isExpense
            ? ['#EF4444', '#F87171', '#FCA5A5', '#FEE2E2']
            : ['#10B981', '#34D399', '#6EE7B7', '#D1FAE5'];

        return {
            chartData: data.chart.map((item, index) => ({
                legend: `${item.name} - ${formatCurrency(item.value)}`,
                data: item.value,
                color: colors[index % colors.length]
            })),
            width: 400,
            height: 400
        };
    };


    const renderStats = (data, isExpense) => {
        const stats = [
            { label: 'Total Amount', value: formatCurrency(data.total) },
            { label: 'Categories', value: data.chart.length },
            { label: 'Average', value: formatCurrency(data.total / (data.transactions.length || 1)) }
        ];

        return stats.map((stat, index) => (
            <div key={index} className={classes.statItem}>
                <div className={classes.statLabel}>{stat.label}</div>
                <div className={classes.statValue} style={{ color: isExpense ? '#DC2626' : '#059669' }}>
                    {stat.value}
                </div>
            </div>
        ));
    };

    return (
        <div className={classes.chartContainer}>
            {/* Deposits Chart */}
            <div className={classes.chartCard}>
                <Text className={classes.chartTitle}>
                    {depositsData.transactions.length.toLocaleString()} Deposits
                </Text>
                <div className={classes.chartStats}>
                    {renderStats(depositsData, true)}
                </div>
                <div className={classes.chartArea}>
                    <div style={{ width: '100%', height: '300px', position: 'relative' }}>
                        <DonutChart
                            data={formatChartData(depositsData, false)}
                            innerRadius={65}
                            legendsOverflowText={'More categories'}
                            hideLegend={false}
                            valueInsideDonut={formatCurrency(depositsData.total)}
                            styles={{
                                root: { width: '100%', height: '100%' }
                            }}
                        />
                    </div>
                </div>
            </div>

            {/* Expenses Chart */}
            <div className={classes.chartCard}>
                <Text className={classes.chartTitle}>
                    {expensesData.transactions.length.toLocaleString()} Expenses
                </Text>
                <div className={classes.chartStats}>
                    {renderStats(expensesData, true)}
                </div>
                <div className={classes.chartArea}>
                    <DonutChart
                        data={formatChartData(expensesData, true)} // Changed to true for expenses
                        innerRadius={65}
                        legendsOverflowText={'More categories'}
                        hideLegend={false}
                        valueInsideDonut={formatCurrency(expensesData.total)}
                        styles={{
                            root: { width: '100%', height: '100%' }
                        }}
                    />
                </div>
            </div>
        </div>
    );
});

// Styles
const useStyles = makeStyles({
    overlaySpinner: {
        position: 'fixed',
        top: '0',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: '40px',
        zIndex: 9999,
    },
    spinnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
        padding: '24px',
        backgroundColor: 'white',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    spinnerIcon: {
        width: '32px',
        height: '32px',
        animation: 'spin 1s linear infinite',
    },
    spinnerText: {
        fontSize: '16px',
        color: tokens.colorNeutralForeground1,
        fontWeight: '500',
    },
    '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        gap: '24px',
        padding: '24px',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
    },
    headerSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    headerBar: {
        height: "48px",
        backgroundColor: 'transparent',
        justifyContent: 'space-between',
        padding: '0 12px',
        marginBottom: '16px',
        gap: '12px'
    },
    toolbarGroup: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
    },
    searchBox: {
        width: '300px',
    },
    gridHeader: {
        background: tokens.colorBrandBackground2,
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '& th': {
            color: tokens.colorNeutralForeground1,
            fontWeight: '600',
            padding: '14px 24px',
            fontSize: '13px',
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
        }
    },
    gridRow: {
        width: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        }
    },
    gridCell: {
        padding: '12px 24px',
        fontSize: '14px',
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,

    },
    accountHeaderRow: {
        backgroundColor: tokens.colorNeutralBackground3,
        fontWeight: '600',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3Hover,
        }
    },
    transactionRow: {
        width: '100%',
        cursor: 'pointer',
        backgroundColor: tokens.colorNeutralBackground1,
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        }
    },
    accountName: {
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
    },
    accountNumber: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
    },
    secondaryText: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground2,
        marginTop: '2px',
    },
    cellAmount: {
        fontFamily: 'monospace',
        fontWeight: '500',
        '&[data-negative="true"]': {
            color: tokens.colorPaletteRedForeground1,
        },
        '&[data-positive="true"]': {
            color: tokens.colorPaletteGreenForeground1,
        }
    },
    expandedRow: {
        display: 'flex',
        backgroundColor: tokens.colorNeutralBackground2,
    },
    expandedCell: {
        padding: '0 !important',
        backgroundColor: 'transparent !important',
        width: '100%',
    },

    headerButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 16px',
        borderRadius: '8px',
        border: '1px solid #E5E7EB',
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F9FAFB',
        },
    },
    dateRangeButton: {
        backgroundColor: '#EFF6FF',
        borderColor: '#BFDBFE',
        '&:hover': {
            backgroundColor: '#DBEAFE',
        },
    },
    tagGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        padding: '8px',
    },
    tag: {
        backgroundColor: '#EFF6FF',
        borderRadius: '6px',
        padding: '4px 8px',
    },
    contentSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        flex: 1,
    },
    chartContainer: {
        display: 'flex',
        gap: '32px',
        padding: '24px',
        background: 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)',
        borderRadius: '24px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        position: 'relative',
        height: '500px',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '4px',
            background: 'linear-gradient(90deg, #3B82F6 0%, #10B981 100%)',
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px'
        }
    },
    chartCard: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '16px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)'
        }
    },
    chartTitle: {
        fontSize: '1.5rem',
        fontWeight: '600',
        background: 'linear-gradient(90deg, #2563EB 0%, #3B82F6 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: '16px',
        textAlign: 'center'
    },
    chartStats: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '16px',
        padding: '12px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
    },
    statItem: {
        textAlign: 'center'
    },
    statLabel: {
        fontSize: '0.875rem',
        color: '#6B7280',
        marginBottom: '4px'
    },
    statValue: {
        fontSize: '1.25rem',
        fontWeight: '600',
    },
    chartArea: {
        width: '100%',
        height: '400px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
  
    expandedContent: {
        width: '100%',
        padding: '24px',
        backgroundColor: tokens.colorNeutralBackground2,
        borderTop: `1px solid ${tokens.colorNeutralStroke1}`,
        borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
        transition: 'all 0.2s ease',
        position: 'relative',
        '&:after': {
            content: '""',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '1px',
            background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent)'
        }
    },
    dataGrid: {
        backgroundColor: 'transparent',
        minHeight: "calc(100vh - 205px)",
        maxHeight: "calc(100vh - 205px)",
        overflowY: "auto",
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    detailsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '24px',
        width: '100%',
        maxWidth: '100%',
    },
    detailRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        padding: '12px',
        backgroundColor: tokens.colorNeutralBackground1,
        borderRadius: '6px',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)'
        }
    },
    detailLabel: {
        fontSize: '12px',
        color: tokens.colorNeutralForeground1,
        fontWeight: '500',
        textTransform: 'uppercase',
        letterSpacing: '0.5px'
    },
    detailValue: {
        fontSize: '14px',
        color: tokens.colorNeutralForeground1,
        fontWeight: '500',
        wordBreak: 'break-word',
        lineHeight: '1.4'
    },
    accountRow: {
        backgroundColor: '#DC2626',
        fontWeight: '600',
    },

    positive: {
        color: '#059669',
        fontWeight: '600',
    },
    negative: {
        color: '#DC2626',
        fontWeight: '600',
    },
});

// GraphQL query
const GET_ACCOUNTTRANSACTIONS = gql`
  query getAccountTransactions(
    $applicationId: String!, 
    $contactId: String!, 
    $startDate: DateTime!, 
    $endDate: DateTime!,
    $accountIds: [String!]
  ) {
    transactions(
      applicationId: $applicationId, 
      contactId: $contactId, 
      startDate: $startDate, 
      endDate: $endDate,
      accountIds: $accountIds
    ) {
      accountId
      accountNumberDisplay
      accountName
      transactionId
      category
      payeeName
      description
      type
      transactionDate
      amount
      checkNumber
      source
    }
  }
`;

export default function CashFlow({ application, onRequestRefresh }) {
    const classes = useStyles();
    const { selectedAccounts } = useAccountStore();
    const [isBusy, setIsBusy] = useState(true);
    const [visibleTags, setVisibleTags] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);
    const { startDate, endDate, setTimeframe } = useTimeframeStore();
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [expandedAccounts, setExpandedAccounts] = useState(new Set());
    const [expandedRows, setExpandedRows] = useState(new Set());

    // GraphQL query
    const { data, refetch } = useQuery(GET_ACCOUNTTRANSACTIONS, {
        variables: {
            applicationId: application.id,
            contactId: application.primaryContact.contactId,
            startDate,
            endDate,
            accountIds: selectedAccounts
        },
        onCompleted: data => {
            setTransactions(data.transactions || []);
            setIsBusy(false);
            setIsLoadingData(false);
        },
        nError: error => {
            console.error('Error fetching transactions:', error);
            setIsBusy(false);
            setIsLoadingData(false);
        }
    });




    const handleRowClick = (transactionId) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(transactionId)) {
                newSet.delete(transactionId);
            } else {
                newSet.add(transactionId);
            }
            return newSet;
        });
    };

    // Add TransactionDetails component
    const TransactionDetails = ({ transaction, classes }) => (
        <div className={classes.expandedContent}>
            <div className={classes.detailsGrid}>
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Transaction ID</div>
                    <div className={classes.detailValue}>{transaction.transactionId}</div>
                </div>
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Source</div>
                    <div className={classes.detailValue}>{transaction.source}</div>
                </div>
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Type</div>
                    <div className={classes.detailValue}>{transaction.type || 'N/A'}</div>
                </div>
                {transaction.checkNumber && (
                    <div className={classes.detailRow}>
                        <div className={classes.detailLabel}>Check Number</div>
                        <div className={classes.detailValue}>{transaction.checkNumber}</div>
                    </div>
                )}
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Full Description</div>
                    <div className={classes.detailValue}>{transaction.description}</div>
                </div>
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Payee Name</div>
                    <div className={classes.detailValue}>{transaction.payeeName}</div>
                </div>
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Category</div>
                    <div className={classes.detailValue}>{transaction.category || 'Uncategorized'}</div>
                </div>
                <div className={classes.detailRow}>
                    <div className={classes.detailLabel}>Account Details</div>
                    <div className={classes.detailValue}>
                        {transaction.accountName} (****{transaction.accountNumberDisplay})
                    </div>
                </div>
            </div>
        </div>
    );

    const handleSearch = useCallback(async () => {
        if (!searchText) return;
        setIsLoadingData(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 100));
            const newTags = [...visibleTags, { value: visibleTags.length + 1, text: searchText }];
            setVisibleTags(newTags);
            await applySearch(newTags);
            setSearchText('');
        } finally {
            setIsLoadingData(false);
        }
    }, [searchText, visibleTags]);

    const [transactions, setTransactions] = useState([]);

    // Process transactions for deposits and expenses
    const { deposits, expenses } = useMemo(() => {
        if (!transactions.length) return { deposits: [], expenses: [] };

        const processedTransactions = transactions.map(transaction => {
            const isPlaidAccount = application?.primaryContact?.plaidLink?.items?.some(item =>
                item.accounts?.some(acc => acc.id === transaction.accountId)
            );

            if (isPlaidAccount) {
                // For Plaid: negative = deposit, positive = expense
                return {
                    ...transaction,
                    isDeposit: transaction.amount < 0,
                    processedAmount: transaction.amount
                };
            } else {
                // For Finicity: Use type and positive amounts for deposits
                const isDeposit = transaction.type?.toLowerCase() === 'deposit' ||
                    (transaction.type === null && transaction.amount > 0);
                return {
                    ...transaction,
                    isDeposit,
                    processedAmount: isDeposit ? transaction.amount : -transaction.amount
                };
            }
        });
        return {
            deposits: processedTransactions.filter(t => t.isDeposit),
            expenses: processedTransactions.filter(t => !t.isDeposit)
        };
    }, [transactions, application]);

    const removeTag = (event, { value }) => {
        const updatedTags = visibleTags.filter(tag => tag.value !== value);
        setVisibleTags(updatedTags);

        if (updatedTags.length === 0) {
            // Reset to original data if no tags
            setTransactions(data.transactions || []);
        } else {
            // Reapply remaining filters
            const filteredTransactions = data.transactions.filter(transaction =>
                updatedTags.every(tag =>
                    transaction.description.toLowerCase().includes(tag.text.toLowerCase()) ||
                    transaction.payeeName.toLowerCase().includes(tag.text.toLowerCase())
                )
            );
            setTransactions(filteredTransactions);
        }
    };
    // Process chart data
    const depositsData = useChartData(deposits);
    const expensesData = useChartData(expenses);

    // Process accounts hierarchy
    const processedAccounts = useMemo(() => {
        if (!transactions.length) return [];

        const groupedByAccount = _.groupBy(transactions, 'accountId');
        return Object.entries(groupedByAccount).map(([accountId, accountTransactions]) => {
            const account = accountTransactions[0];
            const categoryGroups = _.groupBy(accountTransactions,
                transaction => transaction.category || 'Uncategorized'
            );

            const categories = Object.entries(categoryGroups).map(([category, transactions]) => ({
                category,
                transactions,
                total: _.sumBy(transactions, 'amount'),
                count: transactions.length
            }));

            return {
                accountId,
                accountName: account.accountName,
                accountNumber: account.accountNumberDisplay,
                categories,
                total: _.sumBy(accountTransactions, 'amount')
            };
        });
    }, [transactions]);


    // Update the formatBalance function to handle the processed amounts
    const formatBalance = (amount, accountId) => {
        if (amount === undefined || amount === null) return '-';

        const isPlaidAccount = application?.primaryContact?.plaidLink?.items?.some(item =>
            item.accounts?.some(acc => acc.id === accountId)
        );

        // For display purposes, always show positive numbers with the appropriate sign
        const displayAmount = Math.abs(amount);

        return displayAmount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    const handleAccountClick = useCallback((accountId) => {
        setIsLoadingData(true);
        try {
            setExpandedAccounts(prev => {
                const newSet = new Set(prev);
                if (newSet.has(accountId)) {
                    newSet.delete(accountId);
                } else {
                    newSet.add(accountId);
                }
                return newSet;
            });
        } finally {
            setIsLoadingData(false);
        }
    }, []);



  
    const columns = useMemo(() => [
        createTableColumn({
            columnId: 'description',
            renderHeaderCell: () => 'Description',
            compare: (a, b) => {
                // Sort accounts first, then by description/payee name
                if (a.isAccount && !b.isAccount) return -1;
                if (!a.isAccount && b.isAccount) return 1;
                if (a.isAccount && b.isAccount) {
                    return a.description.localeCompare(b.description);
                }
                return (a.payeeName || a.description).localeCompare(b.payeeName || b.description);
            },
            renderCell: (item) => {
                const isAccount = item.isAccount;
                return (
                    <TableCellLayout>
                        <div
                            onClick={() => isAccount && handleAccountClick(`account-${item.accountId}`)}
                            style={{
                                cursor: isAccount ? 'pointer' : 'default',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}
                        >
                            {isAccount && (
                                <span style={{ display: 'flex', transition: 'transform 0.2s' }}>
                                    <ChevronRightRegular
                                        style={{
                                            transform: expandedAccounts.has(`account-${item.accountId}`) ? 'rotate(90deg)' : 'none'
                                        }}
                                    />
                                </span>
                            )}
                            {isAccount ? (
                                <div className={classes.accountName}>{item.description}</div>
                            ) : (
                                <div>
                                    <div>{item.payeeName}</div>
                                    {item.payeeName !== item.description && (
                                        <div className={classes.secondaryText}>{item.description}</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </TableCellLayout>
                );
            }
        }),
        createTableColumn({
            columnId: 'date',
            renderHeaderCell: () => 'Date',
            compare: (a, b) => {
                // Accounts should stay at top when sorting by date
                if (a.isAccount && !b.isAccount) return -1;
                if (!a.isAccount && b.isAccount) return 1;
                if (!a.transactionDate || !b.transactionDate) return 0;
                return new Date(b.transactionDate) - new Date(a.transactionDate); // Most recent first
            },
            renderCell: (item) => (
                <TableCellLayout>
                    {item.transactionDate ? new Date(item.transactionDate).toLocaleDateString() : ''}
                </TableCellLayout>
            )
        }),
        createTableColumn({
            columnId: 'account',
            renderHeaderCell: () => 'Account',
            compare: (a, b) => {
                // Keep accounts at top, then sort by account name
                if (a.isAccount && !b.isAccount) return -1;
                if (!a.isAccount && b.isAccount) return 1;
                return (a.accountName || '').localeCompare(b.accountName || '');
            },
            renderCell: (item) => (
                <TableCellLayout>
                    {!item.isAccount && (
                        <div>
                            <div className={classes.accountName}>{item.accountName}</div>
                            <div className={classes.accountNumber}>****{item.accountNumberDisplay}</div>
                        </div>
                    )}
                </TableCellLayout>
            )
        }),
        createTableColumn({
            columnId: 'source',
            renderHeaderCell: () => 'Source',
            compare: (a, b) => {
                // Keep accounts at top, then sort by source
                if (a.isAccount && !b.isAccount) return -1;
                if (!a.isAccount && b.isAccount) return 1;
                return (a.source || '').localeCompare(b.source || '');
            },
            renderCell: (item) => (
                <TableCellLayout>
                    {!item.isAccount && <div>{item.source}</div>}
                </TableCellLayout>
            )
        }),
        createTableColumn({
            columnId: 'category',
            renderHeaderCell: () => 'Category',
            compare: (a, b) => {
                // Keep accounts at top, then sort by category
                if (a.isAccount && !b.isAccount) return -1;
                if (!a.isAccount && b.isAccount) return 1;
                return (a.category || 'Uncategorized').localeCompare(b.category || 'Uncategorized');
            },
            renderCell: (item) => (
                <TableCellLayout>
                    {!item.isAccount && (item.category || 'Uncategorized')}
                </TableCellLayout>
            )
        }),
        createTableColumn({
            columnId: "amount",
            renderHeaderCell: () => "Amount",
            compare: (a, b) => {
                // Keep accounts at top, then sort by the actual numerical amount
                if (a.isAccount && !b.isAccount) return -1;
                if (!a.isAccount && b.isAccount) return 1;
                return b.amount - a.amount; // Largest amounts first
            },
            renderCell: (item) => {
                if (item.isAccount) return null;

                const { amount, source } = item;
                let isNegative;
                if (source?.toLowerCase() === 'plaid') {
                    isNegative = amount > 0;
                } else {
                    isNegative = amount < 0;
                }

                const formattedAmount = Math.abs(amount).toLocaleString("en-us", {
                    style: "currency",
                    currency: "USD"
                });

                return (
                    <TableCellLayout>
                        <span
                            className={classes.cellAmount}
                            data-negative={isNegative}
                            data-positive={!isNegative}
                        >
                            {isNegative ? '-' : ''}{formattedAmount}
                        </span>
                    </TableCellLayout>
                );
            }
        })
    ], [expandedAccounts, handleAccountClick, classes]);

    // Add this with the other handlers in the CashFlow component
    const handleDateRangeSelect = async (start, end) => {
        try {
            setIsLoadingData(true);
            setTimeframe(start, end);
            setShowDateRangeDialog(false);

            const result = await refetch({
                variables: {
                    applicationId: application.id,
                    contactId: application.primaryContact.contactId,
                    startDate: start,
                    endDate: end,
                    accountIds: selectedAccounts
                }
            });

            setTransactions(result.data.transactions || []);
        } catch (error) {
            console.error('Failed to fetch transactions:', error);
        } finally {
            setIsLoadingData(false);
        }
    };

    const applySearch = async (tags) => {
        if (!data?.transactions) return;
        setIsLoadingData(true);
        try {
            await new Promise(resolve => setTimeout(resolve, 100));
            let filteredTransactions = data.transactions;
            tags.forEach((tag) => {
                filteredTransactions = filteredTransactions.filter(item =>
                    item.description.toLowerCase().includes(tag.text.toLowerCase()));
            });
            setTransactions(filteredTransactions);
        } finally {
            setIsLoadingData(false);
        }
    };

    // Create table items
    const tableItems = useMemo(() => {
        const items = [];
        processedAccounts.forEach(account => {
            const accountId = `account-${account.accountId}`;
            items.push({
                transactionId: accountId,
                description: `${account.accountName} (${account.accountNumber})`,
                total: account.total,
                isAccount: true,
                accountId: account.accountId
            });

            if (expandedAccounts.has(accountId)) {
                account.categories.forEach(category => {
                    const categoryTransactions = category.transactions
                        .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))
                        .map(transaction => {
                            const isPlaidAccount = application?.primaryContact?.plaidLink?.items?.some(item =>
                                item.accounts?.some(acc => acc.id === transaction.accountId)
                            );

                            let isExpense;
                            if (isPlaidAccount) {
                                isExpense = transaction.amount > 0;
                            } else {
                                isExpense = transaction.type?.toLowerCase() === 'withdrawal' ||
                                    (transaction.type === null && transaction.amount < 0);
                            }

                            return {
                                ...transaction,
                                isExpense,
                                displayAmount: isExpense ? -Math.abs(transaction.amount) : Math.abs(transaction.amount)
                            };
                        });

                    categoryTransactions.forEach(transaction => {
                        items.push({
                            ...transaction,
                            transactionId: `${accountId}-${transaction.transactionId}`,
                            parentAccount: account.accountId
                        });
                    });
                });
            }
        });
        return items;
    }, [processedAccounts, expandedAccounts, application]);


    // Render
    return (
        <div className={classes.root}>
            {/* Header Section */}
          

            {/* Content Section */}
            <div className={classes.contentSection}>
                <ChartSection
                    depositsData={depositsData}
                    expensesData={expensesData}
                    classes={classes}
                />
                <div className={classes.headerSection}>
                    <Toolbar className={classes.headerBar}>
                        <ToolbarGroup className={classes.toolbarGroup}>
                            <SearchBox
                                placeholder="Search transactions..."
                                value={searchText}
                                onChange={(event) => setSearchText(event.target.value)}
                                className={classes.searchBox}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleSearch();
                                    }
                                }}
                            />
                            <button
                                onClick={handleSearch}
                                className={classes.headerButton}
                            >
                                <SearchRegular />
                                Search
                            </button>
                        </ToolbarGroup>

                        <ToolbarGroup className={classes.toolbarGroup}>
                            <button
                                onClick={() => setShowDateRangeDialog(true)}
                                className={mergeClasses(classes.headerButton, classes.dateRangeButton)}
                            >
                                <CalendarSearchRegular />
                                {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
                            </button>
                            <button
                                onClick={onRequestRefresh}
                                className={classes.headerButton}
                            >
                                <ArrowSyncCircleRegular />
                                Refresh
                            </button>
                        </ToolbarGroup>
                    </Toolbar>

                    {visibleTags.length > 0 && (
                        <TagGroup
                            onDismiss={removeTag}
                            aria-label="Active filters"
                            className={classes.tagGroup}
                        >
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    key={index}
                                    value={tag.value}
                                    className={classes.tag}
                                >
                                    {tag.text}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <DataGrid
                    className={classes.dataGrid}
                    items={tableItems.map(item => ({
                        ...item,
                        isExpanded: expandedRows.has(item.transactionId)
                    }))}
                    columns={columns}
                    sortable
                >
                    <DataGridHeader className={classes.gridHeader}>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody>
                        {({ item }) => !item.isAccount ? (
                            <React.Fragment key={item.transactionId}>
                                <DataGridRow
                                    className={classes.transactionRow}
                                    onClick={() => handleRowClick(item.transactionId)}
                                >
                                    {({ renderCell }) => (
                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                    )}
                                </DataGridRow>
                                {item.isExpanded && (
                                    <tr className={classes.expandedRow}>
                                        <td colSpan={columns.length} className={classes.expandedCell}>
                                            <TransactionDetails
                                                transaction={item}
                                                classes={classes}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ) : (
                            <DataGridRow
                                key={item.transactionId}
                                className={classes.accountHeaderRow}
                            >
                                {({ renderCell }) => (
                                    <DataGridCell>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </div>

         

            <DateRangeSelectDialog
                startDate={startDate}
                endDate={endDate}
                showDialog={showDateRangeDialog}
                onClose={() => setShowDateRangeDialog(false)}
                onSelect={handleDateRangeSelect}
            />

            {isLoadingData && (
                <div className={classes.overlaySpinner}>
                    <div className={classes.spinnerContainer}>
                        <ArrowSyncCircleRegular className={classes.spinnerIcon} />
                        <span className={classes.spinnerText}>Loading Cash Flow Data...</span>
                    </div>
                </div>
            )}
        </div>
    );
}