import { create } from "zustand";

const userStore = (set, get) => ({
    // Initial state
    email: "",
    id: "",
    mobilePhone: "",
    name: "",
    roles: [],
    status: "",
    title: null,

    // Set user action
    setUser: (user) => {
        set({
            email: user?.email || "",
            id: user?.id || "",
            mobilePhone: user?.mobilePhone || "",
            name: user?.name || "",
            roles: user?.roles || [],
            status: user?.status || "",
            title: user?.title || null
        });
    },

    // Clear user action (optional but useful)
    clearUser: () => {
        set({
            email: "",
            id: "",
            mobilePhone: "",
            name: "",
            roles: [],
            status: "",
            title: null
        });
    }
});

const useUserStore = create(userStore);
export default useUserStore;