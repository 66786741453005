import React, { useState, useCallback } from 'react';
import {
    makeStyles,
    tokens,
    Input,
    Button,
    Card,
    Avatar,
    Caption1,
    Text,
} from "@fluentui/react-components";
import { CloudArrowUpFilled, PersonRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '10px',
    },
    header: {
        marginBottom: '32px',
        textAlign: 'center',
    },
    title: {
        fontSize: '28px',
        fontWeight: '600',
        background: 'linear-gradient(45deg, #2563eb, #3b82f6)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: '8px',
    },
    subtitle: {
        color: tokens.colorNeutralForeground2,
        fontSize: '16px',
    },
    profileCard: {
        padding: '32px',
        borderRadius: '16px',
        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.08)',
        background: 'linear-gradient(145deg, #ffffff, #f8fafc)',
        
    },
    imageSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '32px',
        position: 'relative',
    },
    avatarUpload: {
        width: '120px',
        height: '120px',
        borderRadius: '60px',
        cursor: 'pointer',
        position: 'relative',
        transition: 'all 0.2s ease',
        '&:hover': {
            transform: 'scale(1.05)',
            '& .uploadOverlay': {
                opacity: 1,
            }
        },
    },
    uploadOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(59, 130, 246, 0.8)',
        borderRadius: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity 0.2s ease',
    },
    uploadIcon: {
        color: 'white',
        fontSize: '24px',
    },
    form: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '24px',
        marginTop: '32px',
    },
    field: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    label: {
        fontSize: '14px',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
    },
    input: {
        height: '44px',
        borderRadius: '8px',
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        padding: '0 16px',
        fontSize: '14px',
        transition: 'all 0.2s ease',
        backgroundColor: 'white',
        '&:hover': {
            borderColor: tokens.colorBrandBackground,
        },
        '&:focus': {
            borderColor: tokens.colorBrandBackground,
            boxShadow: '0 0 0 2px rgba(59, 130, 246, 0.1)',
        },
    },
    buttonContainer: {
        gridColumn: '1 / -1',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px',
        marginTop: '16px',
    },
    updateButton: {
        backgroundColor: tokens.colorBrandBackground,
        color: 'white',
        padding: '10px 24px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '500',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
            transform: 'translateY(-1px)',
        },
        '&:active': {
            transform: 'translateY(0)',
        },
    },
});

export default function Profile() {
    const styles = useStyles();
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        profileImage: null
    });

    const handleImageUpload = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileData(prev => ({
                    ...prev,
                    profileImage: e.target.result
                }));
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const handleInputChange = useCallback((field, value) => {
        setProfileData(prev => ({
            ...prev,
            [field]: value
        }));
    }, []);

    const handleSubmit = useCallback(() => {
        // Handle form submission
        console.log('Profile data:', profileData);
    }, [profileData]);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.title}>Personal Profile Information</h1>

            </div>

            <Card className={styles.profileCard}>
                <div className={styles.imageSection}>
                    <div
                        className={styles.avatarUpload}
                        onClick={() => document.getElementById('imageUpload').click()}
                    >
                        <Avatar
                            image={profileData.profileImage}
                            icon={<PersonRegular />}
                            size={120}
                        />
                        <div className="uploadOverlay">
                            <CloudArrowUpFilled className={styles.uploadIcon} />
                        </div>
                    </div>
                    <input
                        id="imageUpload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: 'none' }}
                    />
                    <Caption1 style={{ marginTop: '12px', color: tokens.colorNeutralForeground2 }}>
                        Click to upload profile picture
                    </Caption1>
                </div>

                <div className={styles.form}>
                    <div className={styles.field}>
                        <label className={styles.label}>First Name</label>
                        <Input
                            className={styles.input}
                            value={profileData.firstName}
                            onChange={e => handleInputChange('firstName', e.target.value)}
                        />
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Last Name</label>
                        <Input
                            className={styles.input}
                            value={profileData.lastName}
                            onChange={e => handleInputChange('lastName', e.target.value)}
                        />
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Email</label>
                        <Input
                            className={styles.input}
                            type="email"
                            value={profileData.email}
                            onChange={e => handleInputChange('email', e.target.value)}
                        />
                    </div>

                    <div className={styles.field}>
                        <label className={styles.label}>Mobile Phone</label>
                        <Input
                            className={styles.input}
                            type="tel"
                            value={profileData.mobilePhone}
                            onChange={e => handleInputChange('mobilePhone', e.target.value)}
                        />
                    </div>

                    <div className={styles.buttonContainer}>
                        <Button
                            className={styles.updateButton}
                            onClick={handleSubmit}
                        >
                            Update Profile
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
}