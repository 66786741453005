import {
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    TableCellLayout,
    makeStyles,
    tokens,
    Button,
    Badge,
    Card,
    Text,
    Checkbox
} from "@fluentui/react-components";
import React, { useMemo } from "react";

import useTenantStore from 'stores/tenantStore';
import {
    PersonRegular,
    MailRegular,
    PhoneRegular,
    ShieldLockRegular,
    EditRegular,
    DeleteRegular,
    EyeTrackingRegular,
    PenRegular
} from "@fluentui/react-icons";

const useStyles = makeStyles({
    container: {
        padding: "24px",
        maxWidth: "1200px",
        margin: "0 auto",
    },
    header: {
        marginBottom: "24px",
    },
    headerTitle: {
        fontSize: "24px",
        fontWeight: "600",
        color: tokens.colorNeutralForeground1,
        marginBottom: "8px",
    },
    headerSubtitle: {
        color: tokens.colorNeutralForeground2,
    },
    rolesBadgeContainer: {
        display: "flex",
        gap: "4px",
        flexWrap: "wrap",
    },
    roleBadge: {
        backgroundColor: tokens.colorBrandBackground2,
        color: tokens.colorBrandForeground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
        textTransform: "capitalize",
    },
    dataGrid: {
        backgroundColor: tokens.colorNeutralBackground1,
        border: `1px solid ${tokens.colorNeutralStroke1}`,
        borderRadius: "8px",
        overflow: "hidden",
    },
    gridHeader: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
    actionButton: {
        minWidth: "32px",
        height: "32px",
        padding: "0 8px",
    },
    actionButtonsContainer: {
        display: "flex",
        gap: "8px",
    },
    statusActive: {
        color: tokens.colorPaletteGreenForeground1,
        backgroundColor: tokens.colorPaletteGreenBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    },
    statusInactive: {
        color: tokens.colorPaletteRedForeground1,
        backgroundColor: tokens.colorPaletteRedBackground1,
        padding: "2px 8px",
        borderRadius: "12px",
        fontSize: "12px",
    }
});

export default function TenantEmails() {
    const styles = useStyles();
    const tenantStore = useTenantStore();



    const dummyData = [
        { id: 1, name: "New Application" },
        { id: 2, name: "New Submission"},
        { id: 3, name: "Invite Applicant" },
        { id: 4, name: "Reject Applicant" },
        { id: 5, name: "Follow Up" },
   
        // Add more dummy data objects as needed
    ];


    const columns = useMemo(() => [



        createTableColumn({
            columnId: "name",
            renderHeaderCell: () => (
                <TableCellLayout>
                    Active
                </TableCellLayout>

            ),
            renderCell: (item) => (
                <TableCellLayout>
                    <Checkbox />
                 
                </TableCellLayout>
            ),
        }),
createTableColumn({
    columnId: "TemplateName",
    renderHeaderCell: () => (
        <TableCellLayout>
            Email Template Name
        </TableCellLayout>
    ),
    renderCell: (item) => {
        // Assuming `item` is each row data from dummyData
        return (
            <TableCellLayout>
                <Text>Sample Template Name</Text> {/* Access the name directly from item */}
            </TableCellLayout>
        );
    },
}),


        createTableColumn({
            columnId: "preview",
            renderHeaderCell: () => "Preview",
            renderCell: (item) => (
                <TableCellLayout>
                    <div className={styles.actionButtonsContainer}>
                        <Button
                            icon={<EyeTrackingRegular />}
                            appearance="subtle"
                            className={styles.actionButton}
                            title="Edit user"
                        />
                      
                    </div>
                </TableCellLayout>
            ),
        }),


        createTableColumn({
            columnId: "edit",
            renderHeaderCell: () => "Edit",
            renderCell: (item) => (
                <TableCellLayout>
                    <div className={styles.actionButtonsContainer}>

                        <Button
                            icon={<PenRegular />}
                            appearance="subtle"
                            className={styles.actionButton}
                            title="Delete user"
                        />
                    </div>
                </TableCellLayout>
            ),
        }),
    ], [styles]);

    return (
        <div >
            <div className={styles.header}>
                <Text className={styles.headerTitle}>Lender Email Templates</Text>

            </div>

            <Card>
                <DataGrid
                    items={tenantStore.tenantUsers}
                    columns={columns}
                    sortable
                    getRowId={item => item.id}
                    className={styles.dataGrid}
                >
                    <DataGridHeader className={styles.gridHeader}>
                        <DataGridRow>
                            {({ renderHeaderCell }) => (
                                <DataGridHeaderCell>
                                    {renderHeaderCell()}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody>
                        {({ item, rowId }) => (
                            <DataGridRow key={rowId}>
                                {({ renderCell }) => (
                                    <DataGridCell>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </Card>
        </div>
    );
}