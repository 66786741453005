import React, {useState, useEffect, useCallback} from 'react';
import { TenantApiClient } from 'providers/TenantApiClient';
import useMessageStore from 'stores/messageStore';
import { makeStyles, shorthands, tokens, typographyStyles } from "@fluentui/react-components";

import Dropzone from 'react-dropzone'
import "dropzone/dist/dropzone.css";

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Label,
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Spinner,
    Caption1,
  } from "@fluentui/react-components";

  import {
    Dismiss24Regular,
    CheckmarkCircle24Filled,
    ErrorCircle24Filled,
    CloudArrowUp32Regular
} from "@fluentui/react-icons";
import { upload } from '@testing-library/user-event/dist/upload';

const useUploadPDFClasses = makeStyles({
    dialog: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    dialogButton: {
        backgroundColor: tokens.colorNeutralBackground4,
    },
    dropZoneSection: {
        display: "flex",
        flexDirection: "column"
    },
    dropZoneDiv: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderRadius: "4px",
        border: "2px dashed silver",
        backgroundColor: tokens.colorNeutralBackground2,
        color: tokens.colorNeutralForeground2,
        outline: "none",
        transition: "border .24s ease-in-ouit",
        paddingTop: "1em",
        minHeight: "6em",
    },
    dropZoneInput: {
        alignItems: "center",
        textAlign: "center",
    },
    dropZoneLabel: {
        width: "100%",
    },
    statementCard: {
        maxHeight: "400px",
        overflow: "scroll",
    },
    statementTable: {
        minHeight: "300px",
    },
    statementTableBody: {
        maxHeight: "400px",
        height: "100%",
    },
    statementTableFiller: {
        height: "100%",
    },
    poweredByFooter: {
        float: "right",
        width: "100%",
        textAlign: "right",
    },
    errorIcon: {
        color: tokens.colorPaletteRedBackground3,
    },
    successIcon: {
        color: tokens.colorPaletteGreenBackground3,
    },
});

const UploadPDFStatementsDialog = ({application, showDialog, onClose, onComplete}) => {
    const classes = useUploadPDFClasses();
    const messageStore = useMessageStore();
    const [isBusy, setIsBusy] = useState(false);
    const [statements, setStatements] = useState([]);
    const [statusQueue, setStatusQueue] = useState([]);

    const acceptedFiles = {
        'application/pdf': ['.pdf'],
    };

    const maxFileSize = 1 * 1024 * 1024;

    const handleOpenChange = (data) => {
        setStatements([]);
        if (onClose) {
            onClose();
        }
    };

    const onFileDrop = (files) => {
        let idx = statements.length;
        let addedFiles = files.map((file) => {
            idx = idx + 1;
            const newId = idx;
            return {id: newId, description: file.path, status: 'Added', file: file};
        })

        setStatements([...statements, ...addedFiles]);

        addedFiles.map((file) => {
            uploadFile(file.id, file.file);
        });
    };

    const uploadFile = (id, file) => {
        let fileObj = {
            file: file
        };

        TenantApiClient.postFile(`/api/documents/UploadPDF/${application.id}/${application.primaryContact.contactId}`, fileObj)
            .then((data) => {
                updateStatus(id, "Success");
                // add status back as Uploaded


                //TenantApiClient.post(`/api/documents/AnalzePDFBankStatement/${application.id}/${application.primaryContact.contactId}/${data.documentStorageId}`)
                //.then((data) => {
                //    updateStatus(id, "Success");
                //})
                //.catch((error) => {
                //    console.error(error);
                //    updateStatus(id, "Failed");
                //    //add in ocrolus here
                //});
            })
            .catch((error) => {
              
                updateStatus(id, "Failed");
            });
    };

    const updateStatus = (id, status) => {
        setStatements(state => state.map(statement => {
            if (statement.id === id)
            {
                return { ...statement, status: status };
            } else {
                return statement;
            }
        }));
    };


    const columns = [
        { columnKey: "description", label: "Description"},
        { columnKey: "status", label: "Status"},
    ];

    return (
        <Dialog open={showDialog} onOpenChange={handleOpenChange}>
            <DialogSurface className={classes.dialog}>
                <DialogBody>
                    <DialogTitle action={
                        <DialogTrigger action="close">
                            <Button
                            appearance="subtle"
                            aria-label="close"
                            icon={<Dismiss24Regular />}
                            />
                        </DialogTrigger>}>
                        Upload and Analyze PDF Statements</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Label htmlFor={"dropzone-input"}>
                            Upload Statements
                        </Label>
                        <Dropzone maxSize={maxFileSize} accept={acceptedFiles} onDropAccepted={onFileDrop} id="dropzone-input">
                            {({getRootProps, getInputProps}) => (
                                <section className={classes.dropZoneSection}>
                                    <div className={classes.dropZoneDiv} {...getRootProps()}>
                                        <CloudArrowUp32Regular />
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                        <Card className={classes.statementCard}>
                            <Table size="small" className={classes.statementTable}>
                                <TableHeader>
                                    <TableRow>
                                    {columns.map((column) => (
                                        <TableHeaderCell key={column.columnKey}>
                                            {column.label}
                                        </TableHeaderCell>
                                    ))}
                                    </TableRow>
                                </TableHeader>
                                <TableBody className={classes.statementTableBody}>
                                    {isBusy &&
                                        <Spinner />
                                    }
                                    {!isBusy && statements && statements.map((statement) => (
                                        <TableRow key={statement.id}>
                                            <TableCell>
                                                <TableCellLayout className="truncate">
                                                    {statement.description}
                                                </TableCellLayout>
                                            </TableCell>
                                            <TableCell>
                                                <TableCellLayout>
                                                    {(statement.status == "Uploading" || statement.status == "Added") && 
                                                        <Caption1>
                                                            <Spinner size="extra-small" label="Uploading File" />
                                                        </Caption1>
                                                    }
                                                    {(statement.status == "Uploaded" || statement.stsatus == "Analyzing") && 
                                                        <Caption1>
                                                            <Spinner size="extra-small" label="Analyzing File" />
                                                        </Caption1>
                                                    }
                                                    {statement.status == "Success" && 
                                                        <Caption1>
                                                            <CheckmarkCircle24Filled className={classes.successIcon} /> Success
                                                        </Caption1>
                                                    }
                                                    {statement.status == "Failed" && 
                                                        <Caption1>
                                                            <ErrorCircle24Filled className={classes.errorIcon} /> Failed
                                                        </Caption1>
                                                    }
                                                </TableCellLayout>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow className={classes.statementTableFiller}></TableRow>
                                </TableBody>
                            </Table>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button className={classes.dialogButton} disabled={isBusy} appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
)}

export default UploadPDFStatementsDialog;