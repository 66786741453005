import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import useMessageStore from 'stores/messageStore';
import { DocumentThumbnail, DocumentViewer } from 'components/Document';
import { TenantApiClient } from 'providers/TenantApiClient';
import {
    makeStyles,
    Button,
    tokens,
    Card,
    CardHeader,
    Text,
    Caption1,
    Spinner
} from "@fluentui/react-components";
import {
    CalendarArrowDownFilled,
    AttachFilled,
    ArrowDownloadFilled,
    ArrowSyncCircleRegular,
    DocumentPdfRegular,
    DocumentRegular,
    ChevronDownRegular,
    ChevronUpRegular,
    CheckmarkCircle24Filled
} from "@fluentui/react-icons";

import FetchStatementsDialog from 'dialogs/FetchStatements';
import UploadPDFStatementsDialog from 'dialogs/UploadPDFStatements';

const useStyles = makeStyles({
    root: {
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(8px)',
        borderRadius: '16px',
        padding: '24px',
        margin: '20px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        transition: 'all 0.2s ease',
    },
    actionBar: {
        display: 'flex',
        gap: '12px',
        padding: '16px',
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
        flexWrap: 'wrap'
    },
    actionButton: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px 16px',
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        background: 'white',
        transition: 'all 0.2s ease',
        color: tokens.colorNeutralForeground1,
        '&:hover:not(:disabled)': {
            backgroundColor: 'rgba(0, 120, 212, 0.08)',
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)'
        },
        '&:disabled': {
            backgroundColor: tokens.colorNeutralBackground4,
            color: tokens.colorNeutralForeground4,
            cursor: 'not-allowed'
        }
    },
    documentsGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        gap: '24px',
        padding: '24px',
        width: '100%',
        maxWidth: '1200px',
        margin: '0 auto'
    },
    documentCard: {
        background: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)'
        }
    },
    thumbnail: {
        width: '100%',
        position: 'relative',
        background: tokens.colorNeutralBackground3,
        borderRadius: '8px 8px 0 0',
        overflow: 'hidden',
        height: '240px', // Fixed height for thumbnails
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    thumbnailContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
            padding: '16px'
        },
        '& svg': {
            width: '48px',
            height: '48px',
            color: tokens.colorNeutralForeground3
        }
    },
    documentInfo: {
        padding: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    documentTitle: {
        fontSize: '0.875rem',
        fontWeight: '500',
        color: tokens.colorNeutralForeground1,
        lineHeight: '1.2',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    },
    documentActions: {
        padding: '12px',
        borderTop: `1px solid ${tokens.colorNeutralBackground4}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    section: {
        margin: '24px 0',
        background: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)'
    },
    sectionHeader: {
        padding: '16px 20px',
        borderBottom: `1px solid ${tokens.colorNeutralBackground4}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2
        }
    }
});


const GET_ACCOUNT_STATEMENTS = gql`
query getAccountStatements($applicationId: String!, $contactId: String!) {
    accounts(applicationId: $applicationId, contactId: $contactId) {
        source,
        accountId,
        accountNumberDisplay,
        accountName,
        institutionId,
        institutionName,
        institutionLogo,
        institutionIcon,
        isTransactionSynced,
        isHistoricTransactionsEnabled,
        isStatementsSynced,
        isVisible,
        uploadedStatements {
            id,
            status,
            description,
            documentStorageId,
            asOfDate
        },
        plaidStatements {
            id,
            status,
            description,
            documentStorageId,
            asOfDate
        },
        bankStatements {
            id,
            status,
            description,
            documentStorageId,
            asOfDate
        }
    }
}`;


const ActionButton = ({ icon: Icon, onClick, disabled, children }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className="flex items-center gap-2 px-4 py-2 rounded-lg border border-gray-200 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed transition-all"
    >
        <Icon />
        {children}
    </button>
);
const DocumentSection = ({ title, subtitle, documents, application, onViewDocument, onUseOcrolus, onFetchResults, onCheckStatus, ocrolusStatus }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(true);

    if (!documents?.length) return null;


    return (
        <div className={classes.section}>
            <div className={classes.sectionHeader} onClick={() => setIsExpanded(!isExpanded)}>
                <div>
                    <Text weight="semibold" size={500}>{title}</Text>
                    <Caption1>{subtitle}</Caption1>
                </div>
                {isExpanded ? <ChevronUpRegular /> : <ChevronDownRegular />}
            </div>

            {isExpanded && documents.length > 0 && (
                <div className={classes.documentsGrid}>
                    {documents.map(statement => (
                        <StatementCard
                            key={statement.id}
                            application={application}
                            statement={statement}
                            onClick={() => onViewDocument(statement)}
                            onUseOcrolus={onUseOcrolus}
                            onFetchResults={onFetchResults}
                            onCheckStatus={onCheckStatus}
                            ocrolusStatus={ocrolusStatus}
                            isUploadedDocument={true}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const StatementCard = ({ application, statement, onClick, ocrolusStatus = {}, onUseOcrolus, onFetchResults, onCheckStatus, isUploadedDocument }) => {
    const classes = useStyles();
    if (!statement || !application) return null;

    const currentStatus = statement.documentStorageId ? ocrolusStatus[statement.documentStorageId] || null : null;
    const applicationStatus = application.ocrolusBookStatus?.toUpperCase();

    const showOcrolusButton = isUploadedDocument &&
        (!applicationStatus || applicationStatus === 'FAILED' );

    // Show Check Status when applicationStatus is VERIFYING
    const showCheckStatusButton = isUploadedDocument &&
        (applicationStatus === 'VERIFYING' || applicationStatus === 'PROCESSING');

    const showFetchButton = isUploadedDocument &&
        (applicationStatus === 'CREATED' || applicationStatus === 'VERIFIED' );

    const showSyncedStatus =  applicationStatus === 'SYNCED';

    return (
        <div className={classes.documentCard}>
            <div onClick={onClick}>
                <div className={classes.thumbnail}>
                    <div className={classes.thumbnailContent}>
                        {statement.documentStorageId ? (
                            <DocumentThumbnail
                                id={statement.documentStorageId}
                                alt={statement.description || 'Document thumbnail'}
                            />
                        ) : (
                            <DocumentRegular />
                        )}
                    </div>
                </div>
                <div className={classes.documentInfo}>
                    <Text className={classes.documentTitle}>
                        {statement.description || 'Untitled Document'}
                    </Text>
                    <Caption1>Status: {applicationStatus || 'Pending'}</Caption1>
                </div>
            </div>

            {isUploadedDocument && statement.documentStorageId && (
                <div className={classes.documentActions}>
                    {showOcrolusButton && (
                        <Button
                            appearance="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                onUseOcrolus(statement);
                            }}
                        >
                            Use Ocrolus
                        </Button>
                    )}
                    {showCheckStatusButton && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                onCheckStatus(statement);
                            }}
                        >
                            Check Status
                        </Button>
                    )}
                    {showFetchButton && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                onFetchResults(statement);
                            }}
                        >
                            Fetch Results
                        </Button>
                    )}
                    {showSyncedStatus && (
                        <div className="flex items-center justify-center p-2">
                            <CheckmarkCircle24Filled className="text-green-500 w-6 h-6" />
                            <span className="ml-2 text-green-500">Synced</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const StatementsPage = ({ application, onRequestRefresh }) => {
    const messageStore = useMessageStore();
    const [accounts, setAccounts] = useState(null);
    const [showFetchStatements, setShowFetchStatements] = useState(false);
    const [showUploadPdfStatements, setShowUploadPdfStatements] = useState(false);
    const [viewDocument, setViewDocument] = useState(false);
    const [currentDocId, setCurrentDocId] = useState(null);

    const [ocrolusStatus, setOcrolusStatus] = useState(() => {
        if (application?.OcrolusBookStatus) {
            return { [application.id]: application.OcrolusBookStatus };
        }
        return {};
    });

    useEffect(() => {
        if (application?.OcrolusBookStatus) {
            setOcrolusStatus(prev => ({
                ...prev,
                [application.id]: application.OcrolusBookStatus
            }));
        }
    }, [application?.OcrolusBookStatus]);

    const { loading, error, data, refetch } = useQuery(GET_ACCOUNT_STATEMENTS, {
        variables: {
            applicationId: application.id,
            contactId: application.primaryContact.contactId
        },
        onCompleted: (data) => {
            if (data?.accounts) {
     
                setAccounts(data.accounts);
            }
        },
    });

    if (error) {
        messageStore.addApolloError(error);
    }

    const handleRefresh = async () => {
        try {
           
            messageStore.addMessage('Statements refreshed');
            await refetch();
        } catch (error) {
            messageStore.addAxiosError('Failed to refresh statements');
        }
    };

    const handleUseOcrolus = async (statement) => {
        if (!statement.documentStorageId) {
            messageStore.addError('Document ID is missing');
            return;
        }

        const uploadId = encodeURIComponent(statement.documentStorageId);
        setOcrolusStatus(prev => ({
            ...prev,
            [statement.documentStorageId]: 'Uploading...'
        }));

        try {
            await TenantApiClient.post(`/api/documents/Ocrolus/${application.id}/${application.contacts[0].contactId}/${uploadId}`);
            setOcrolusStatus(prev => ({
                ...prev,
                [statement.documentStorageId]: 'Uploaded',
                [application.id]: 'VERIFYING'
            }));
            await refetch();
            if (onRequestRefresh) {
                onRequestRefresh();
            }
        } catch (error) {
            messageStore.addAxiosError('Error processing document', error);
            setOcrolusStatus(prev => ({
                ...prev,
                [statement.documentStorageId]: 'Error'
            }));
        }
    };

    const handleCheckBookStatus = async (statement) => {
        setOcrolusStatus(prev => ({
            ...prev,
            [statement.documentStorageId]: 'Checking Status...'
        }));

        try {
            const response = await TenantApiClient.get(`/api/documents/Ocrolus/${application.id}/status`);
            const newStatus = response?.response?.book_status || 'Unknown';
            setOcrolusStatus(prev => ({
                ...prev,
                [statement.documentStorageId]: newStatus,
                [application.id]: newStatus
            }));
            await refetch();
            // Add background refresh of application data
            if (onRequestRefresh) {
                onRequestRefresh();
            }
        } catch (error) {
            messageStore.addAxiosError('Error checking status', error);
            setOcrolusStatus(prev => ({
                ...prev,
                [statement.documentStorageId]: 'Error'
            }));
        }
    };

    const handleFetchResults = async (statement) => {
        setOcrolusStatus(prev => ({
            ...prev,
            [statement.documentStorageId]: 'Fetching Results...'
        }));

        try {
            const response = await TenantApiClient.get(`/api/documents/Ocrolus/${application.id}/transactions`);
            if (response === "VERIFICATION_COMPLETE") {
                setOcrolusStatus(prev => ({
                    ...prev,
                    [statement.documentStorageId]: 'COMPLETE',
                    [application.id]: 'COMPLETE'
                }));
            } else {
                setOcrolusStatus(prev => ({
                    ...prev,
                    [statement.documentStorageId]: 'Incomplete'
                }));
            }
            await refetch();
            // Add background refresh of application data
            if (onRequestRefresh) {
                onRequestRefresh();
            }
        } catch (error) {
            messageStore.addAxiosError('Error fetching results', error);
            setOcrolusStatus(prev => ({
                ...prev,
                [statement.documentStorageId]: 'Error'
            }));
        }
    };


    const handleViewDocument = (statement) => {
        setCurrentDocId(statement.documentStorageId);
        setViewDocument(true);
    };

    const uploadAccounts = accounts?.filter(account =>
        account.uploadedStatements?.length > 0 || account.bankStatements?.length > 0
    );

    return (
        <div className="bg-gray-50/80 backdrop-blur-md p-6 m-5 rounded-2xl min-h-screen flex flex-col gap-6">
            {viewDocument && (
                <DocumentViewer
                    id={currentDocId}
                    onClose={() => setViewDocument(false)}
                />
            )}

            <div className={viewDocument ? 'hidden' : ''}>
                <div className="flex flex-wrap gap-3 p-4 bg-white rounded-xl shadow-sm mb-6">
                    <ActionButton
                        icon={CalendarArrowDownFilled}
                        onClick={() => setShowFetchStatements(true)}
                    >
                        Fetch Statements
                    </ActionButton>
                    <ActionButton
                        icon={DocumentPdfRegular}
                        onClick={() => setShowUploadPdfStatements(true)}
                    >
                        Upload PDF
                    </ActionButton>
                    <ActionButton
                        icon={AttachFilled}
                        disabled
                    >
                        Attach Documents
                    </ActionButton>
                    <ActionButton
                        icon={ArrowDownloadFilled}
                        disabled
                    >
                        Download All
                    </ActionButton>
                    <ActionButton
                        icon={ArrowSyncCircleRegular}
                        onClick={handleRefresh}
                    >
                        Refresh
                    </ActionButton>
                </div>

                {loading ? (
                    <div className="flex justify-center items-center p-10">
                        <Spinner size="large" />
                    </div>
                ) : (
                    <div>
                        {uploadAccounts?.length > 0 ? (
                            uploadAccounts.map(account => (
                                <DocumentSection
                                    key={account.accountId}
                                    title={"Bank Statement"}
                                    documents={account.uploadedStatements?.length > 0 ? account.uploadedStatements : account.bankStatements}
                                    application={application}
                                    onViewDocument={handleViewDocument}
                                    onUseOcrolus={handleUseOcrolus}
                                    onFetchResults={handleFetchResults}
                                    onCheckStatus={handleCheckBookStatus}
                                    ocrolusStatus={ocrolusStatus}
                                />
                            ))
                        ) : (
                            <p>No accounts with uploaded or bank statements found.</p>
                        )}
                    </div>
                )}
                <FetchStatementsDialog
                    application={application}
                    accounts={accounts || []} // Ensure accounts is never null
                    showDialog={showFetchStatements}
                    onClose={() => {
                        setShowFetchStatements(false);
                      
                    }}
                />
                <UploadPDFStatementsDialog
                    application={application}
                    showDialog={showUploadPdfStatements}
                    onClose={() => {
                        setShowUploadPdfStatements(false);
                        onRequestRefresh();
                    }}
                />
            </div>
        </div>
    );
};

export default StatementsPage;